import * as yup from 'yup';

export const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

export const validationSchemaRegister = yup.object({
  companyName: yup.string().required('Company name is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: yup
    .string()
    .matches(/^\d+$/, 'Phone must contain only digits with no spaces')
    .min(5, 'Phone must be at least 5 characters')
    .required('Phone is required'),
});

export const validateGroupName = yup.object({
  groupName: yup
    .string()
    .required('This field is required')
    .matches(
      /^(?! )[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
      'Must be alphanumeric with single spaces and cannot start with a space'
    ),
});
export const fileNameValidation = /^(?!.*\.\.).*[\w-]+(\.[\w-]+)$/;
