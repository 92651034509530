import React from 'react';

import { Box, CircularProgress } from '@mui/material';
import colors from 'theme/colors';

function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={colors.gradientColor1} />
            <stop offset="100%" stopColor={colors.gradientColor2} />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}
      />
    </React.Fragment>
  );
}
const Loader = () => {
  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <GradientCircularProgress />
    </Box>
  );
};

export default Loader;
