import { createTheme } from '@mui/material/styles';

import colors from './colors'; // Import color constants

// Define your light theme
const lightTheme = createTheme({
  palette: {
    mode: 'light', // Set to 'light' mode
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },

    // Define more colors as needed
  },
  typography: {
    // Customize typography if needed
    fontFamily: ['Poppins', 'Roboto', 'Arial'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Apply a gradient background to all buttons
          textTransform: 'none',
        },
      },
    },
  },
  // Add other theme options if needed
});

// Define your dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark', // Set to 'dark' mode
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    // Define more colors as needed
  },
  typography: {
    // Customize typography if needed
    // fontFamily: '"Poppins","Roboto", "Arial", sans-serif',
    fontFamily: ['Poppins', 'Roboto'].join(','),
  },
  // Add other theme options if needed
});

export { darkTheme, lightTheme };
