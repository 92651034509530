import React, { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FolderIcon from '@mui/icons-material/Folder';
import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { fileScanStatus } from 'config/config';

import NotScannedIcon from '../../assets/icons/NotScanned.svg';
import CustomModal from '../CustomModal';
import CustomIconButton from '../elements/IconButton';
import { FileData } from './FileList';
import RenderFileIcon from './RenderFileIcon';

interface FileItemProps {
  id: string;
  type: 'file' | 'folder';
  name: string;
  modified: string;
  size?: string;
  onSelect?: (checked: boolean) => void;
  onClick?: () => void;
  downloadFile?: () => void;
  uploadedBy?: string;
  getFileVersions: (file: FileData) => void;
  file: FileData;
}

const FileItem: React.FC<FileItemProps> = ({
  id,
  type,
  name,
  modified,
  size,
  onSelect,
  onClick,
  downloadFile,
  uploadedBy,
  getFileVersions,
  file,
}) => {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const handleDownloadFile = () => {
    if (file['scan-status'] !== fileScanStatus.scanned) {
      setShowModal(true);
    } else {
      downloadFile && downloadFile();
    }
  };
  return (
    <>
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: theme.palette.grey[100], // Light version of primary color
            cursor: type === 'folder' ? 'pointer' : 'default',
          },
        }}
      >
        {/* <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          onChange={(e) => onSelect?.(e.target.checked)}
        />
      </TableCell> */}
        <TableCell align="center">
          <Typography variant="body2">
            {type !== 'folder' ? (
              <IconButton onClick={() => handleDownloadFile()}>
                <CloudDownloadIcon color="primary" />
              </IconButton>
            ) : (
              '-'
            )}
          </Typography>
        </TableCell>
        <TableCell>
          {type === 'folder' ? (
            <IconButton onClick={onClick}>
              <FolderIcon sx={{ color: '#ffcc00' }} />
            </IconButton>
          ) : (
            <RenderFileIcon fileType={name.split('.')[1]} />
          )}
        </TableCell>
        <TableCell onClick={type === 'folder' ? onClick : undefined}>
          <Typography variant="body1">{name}</Typography>
        </TableCell>
        <TableCell align="center">
          {file['scan-status'] === fileScanStatus.notScanned ? (
            <CustomIconButton tooltipTitle="Not scanned" buttonType="primary">
              <img src={NotScannedIcon} alt="Not scanned" height={30} />
            </CustomIconButton>
          ) : (
            <CustomIconButton
              tooltipTitle="No virus found"
              buttonType="success"
            >
              <CheckCircleIcon sx={{ color: green[400] }} />
            </CustomIconButton>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2">{modified}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{uploadedBy ?? '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{size || '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            component={Button}
            onClick={() => getFileVersions(file)}
            sx={{ textDecoration: 'none' }}
          >
            {type === 'folder' ? '-' : 'Get details'}
          </Typography>
        </TableCell>
      </TableRow>
      {/* Modal to show when file is not scanned */}
      <CustomModal
        open={showModal}
        onClose={() => setShowModal(false)}
        type="error"
        title="Cannot download !!"
        primaryButtonLabel="Ok"
        primaryButtonAction={() => setShowModal(false)}
      >
        <Typography variant="body1">
          File is not scanned, please try later...
        </Typography>
      </CustomModal>
    </>
  );
};

export default FileItem;
