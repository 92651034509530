import { useState } from 'react';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { Box, Button, Grid, Typography } from '@mui/material';
import GradientCardComponent from 'components/elements/GradientCard';
import HeaderComp from 'components/elements/HeadingComponent';

import CreateStaffGroup from './CreateStaffGroup';
import DeleteStaffGroup from './DeleteStaffGroup';
import DeleteStaffGroupMember from './DeleteStaffGroupMember';

const StaffGroups = () => {
  const options = ['Create staff group', 'Delete staff group'];
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  return (
    <>
      {selectedOption === 'Create staff group' ? (
        <>
          <Box
            component={Button}
            display="flex"
            alignItems="center"
            gap={1}
            mb={2}
            onClick={() => setSelectedOption(null)}
          >
            <FirstPageIcon color="primary" />
            <Typography variant="subtitle1" color="primary">
              Go back
            </Typography>
          </Box>
          <CreateStaffGroup />
        </>
      ) : selectedOption === 'Delete staff group' ? (
        <>
          <Box
            display="flex"
            component={Button}
            alignItems="center"
            gap={1}
            mb={2}
            onClick={() => setSelectedOption(null)}
          >
            <FirstPageIcon color="primary" />
            <Typography variant="subtitle1" color="primary">
              Go back
            </Typography>
          </Box>
          <DeleteStaffGroup />
        </>
      ) : selectedOption === 'Delete staff group member' ? (
        <>
          <Box
            display="flex"
            component={Button}
            alignItems="center"
            gap={1}
            mb={2}
            onClick={() => setSelectedOption(null)}
          >
            <FirstPageIcon color="primary" />
            <Typography variant="subtitle1" color="primary">
              Go back
            </Typography>
          </Box>
          <DeleteStaffGroupMember />
        </>
      ) : (
        <>
          <HeaderComp>Staff groups</HeaderComp>
          <Grid container gap={1}>
            <Grid item lg={3}>
              <GradientCardComponent>
                <Box
                  pt={1}
                  pb={1}
                  display="flex"
                  gap={2}
                  onClick={() => {
                    setSelectedOption('Create staff group');
                  }}
                >
                  <GroupAddIcon />
                  Create staff group
                </Box>
              </GradientCardComponent>
            </Grid>
            <Grid item lg={3}>
              <GradientCardComponent type="error">
                <Box
                  pt={1}
                  pb={1}
                  display="flex"
                  gap={2}
                  onClick={() => {
                    setSelectedOption('Delete staff group');
                  }}
                >
                  <GroupRemoveIcon />
                  <Typography variant="body1">Delete staff group</Typography>
                </Box>
              </GradientCardComponent>
            </Grid>
            <Grid item lg={3}>
              <GradientCardComponent type="error">
                <Box
                  pt={1}
                  pb={1}
                  display="flex"
                  gap={2}
                  onClick={() => {
                    setSelectedOption('Delete staff group member');
                  }}
                >
                  <GroupRemoveIcon />
                  <Typography variant="body1">
                    Delete staff group member
                  </Typography>
                </Box>
              </GradientCardComponent>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default StaffGroups;
