import React from 'react';

import { Box, BoxProps } from '@mui/material';

interface CustomCardProps extends BoxProps {}

const CustomCard: React.FC<CustomCardProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        boxShadow: '5px 10px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: 8,

        ...sx, // Allow overriding styles via `sx`
      }}
      p={3}
      {...props} // Spread additional Box props
    >
      {children}
    </Box>
  );
};

export default CustomCard;
