import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material'; // Using MUI for modal, you can customize as needed.
import { checkPasswordAge } from 'common/utils'; // Assuming this is the function that checks password age.
import { forcePasswordExpireDays } from 'config/config';
import useNavigateWithUUID from 'hooks/useNavigateWithUUID';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';

import { login, logout } from '../redux/slices/authSlice';
import CustomModal from './CustomModal';

interface PasswordAgeWrapperProps {
  children: React.ReactNode;
}

const PasswordAgeWrapper: React.FC<PasswordAgeWrapperProps> = ({
  children,
}) => {
  const { navigateWithUuid } = useNavigateWithUUID();
  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [daysSinceChange, setDaysSinceChange] = useState(0);
  const [willPasswordExpire, setWillPasswordExpire] = useState(false);
  const [isPasswordOld, setIsPasswordOld] = useState(false);
  const { forcePasswordChangeModalAccepted, lastPasswordChangeDate } =
    useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const [passwordOld, passwordExpire, differenceInDays] = checkPasswordAge(
      lastPasswordChangeDate
    );
    if (path === 'change-password') {
      setIsModalOpen(false);
    } else if (passwordExpire && !forcePasswordChangeModalAccepted) {
      setIsModalOpen(true);
      setDaysSinceChange(differenceInDays);
      setWillPasswordExpire(passwordExpire);
      setIsPasswordOld(passwordOld);
    }
  }, [
    lastPasswordChangeDate,
    forcePasswordChangeModalAccepted,
    location.pathname,
  ]);

  const handleClose = () => {
    setIsModalOpen(false);
    dispatch(login({ forcePasswordChangeModalAccepted: true }));
  };
  const handleSecondaryButton = () => {
    isPasswordOld ? dispatch(logout()) : handleClose();
  };

  return (
    <>
      <CustomModal
        open={isModalOpen}
        onClose={handleClose}
        title="Password change required !!"
        type="error"
        autoClose={false}
        primaryButtonAction={() => {
          {
            setIsModalOpen(false);
            navigateWithUuid('change-password');
          }
        }}
        primaryButtonLabel="Change password"
        secondaryButtonAction={handleSecondaryButton}
        secondaryButtonLabel={isPasswordOld ? 'Logout' : 'Cancel'}
      >
        <Typography variant="h6">
          {!isPasswordOld && forcePasswordExpireDays - daysSinceChange !== 0
            ? `Your password will expire in ${forcePasswordExpireDays - daysSinceChange} days. Please change it soon.`
            : forcePasswordExpireDays - daysSinceChange == 0
              ? 'Your password will expire today , do you want to change you password now?'
              : 'Your password has expired, please change your password.'}
        </Typography>
      </CustomModal>
      {children}
    </>
  );
};

export default PasswordAgeWrapper;
