import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reducerCodes } from 'config/config';

export interface idTokenInterface {
  authTime: number;
  phoneNumber: string;
  email: string;
  username: string;
  employeeId: string;
  companyId: string;
  emailVerified: boolean;
}

const initialState: idTokenInterface = {
  authTime: 0,
  phoneNumber: '',
  email: '',
  username: '',
  employeeId: '',
  companyId: '',
  emailVerified: false,
};

const slice11 = createSlice({
  name: reducerCodes.idToken,
  initialState,
  reducers: {
    setIdTokenData(state, action: PayloadAction<Partial<idTokenInterface>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetIdTokenData() {
      return initialState;
    },
  },
});

export const { setIdTokenData, resetIdTokenData } = slice11.actions;
export default slice11.reducer;
