// src/store.ts
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';

import encryptedStorage from './encryptedStorage'; // Import your custom storage
import authReducer from './slices/authSlice';
import idTokenReducer from './slices/idTokenSlice';

// Configuration for redux-persist
const persistConfig = {
  key: 'auth', // Change this to 'auth' if you want to persist only auth-related data
  storage: encryptedStorage, // Use your custom storage
  whitelist: ['auth', '11'], // Only persist the 'auth' slice
};

// Combine your reducers
const rootReducer = combineReducers({
  auth: authReducer,
  '11': idTokenReducer,
  // other slices go here
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
});

// Export the persistor
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// import authReducer from './slices/authSlice';
// import idTokenReducer from './slices/idTokenSlice';

// // Configuration for redux-persist
// const persistConfig = {
//   key: 'auth',
//   storage,
//   whitelist: ['auth', '11'], // Only persist the 'auth' slice
// };

// // Combine your reducers
// const rootReducer = combineReducers({
//   auth: authReducer,
//   '11': idTokenReducer,
//   // other slices go here
// });

// // Create a persisted reducer
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Configure the store with the persisted reducer
// export const store = configureStore({
//   reducer: persistedReducer,
// });

// // Export the persistor
// export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
