import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ForwardIcon from '@mui/icons-material/Forward';
import { Box, Grid, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';
import { getUsers } from 'common/utils';
import { defaultErrorMessage } from 'config/config';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { validateGroupName } from 'validation/validationScheme';

import CustomModal from '../../components/CustomModal';
import GradientButton from '../../components/elements/GradientButton';
import CustomIconButton from '../../components/elements/IconButton';
import InputElement from '../../components/elements/InputElement';
import SelectableList from '../../components/ListItemsWithSearch';
import useSelectableItems from '../../hooks/useSelectableList';
import { getUserInterface } from './utils';

interface groupNameInterface {
  groupName: string;
}
const CreateCorporateGroup = () => {
  const [usersList, setUsersList] = useState<getUserInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [creatingGroup, setCreatingGroup] = useState(false);
  const { companyId, employeeId } = useSelector(
    (state: RootState) => state.auth
  );
  const { control, handleSubmit, reset } = useForm<groupNameInterface>({
    resolver: yupResolver(validateGroupName),
    mode: 'onChange',
    defaultValues: {
      groupName: '',
    } as groupNameInterface,
  });

  const getUsersList = async () => {
    setLoading(true);
    const response = await getUsers(companyId, 'GetCorporate', employeeId);
    if ('error' in response) {
      popupNotification(defaultErrorMessage, false);
    } else {
      setUsersList(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsersList();
  }, [companyId]);

  // State for the left and right lists
  const [leftItems, setLeftItems] = useState<getUserInterface[]>(usersList);
  const [rightItems, setRightItems] = useState<getUserInterface[]>([]);

  // Custom hooks for selecting items on both lists
  const {
    selectedItems: selectedLeftItems,
    toggleItem: toggleLeftItem,
    resetSelection: resetLeftSelection,
  } = useSelectableItems();
  const {
    selectedItems: selectedRightItems,
    toggleItem: toggleRightItem,
    resetSelection: resetRightSelection,
  } = useSelectableItems();

  useEffect(() => {
    setLeftItems(usersList); // Update leftItems whenever usersList changes
  }, [usersList]);

  // Handler to move selected items from left to right
  const handleMoveItemsToRight = () => {
    const updatedRightItems = [
      ...rightItems,
      ...leftItems.filter((item) => selectedLeftItems.includes(item.id)),
    ];
    const updatedLeftItems = leftItems.filter(
      (item) => !selectedLeftItems.includes(item.id)
    );
    // Update the state
    setLeftItems(updatedLeftItems);
    setRightItems(updatedRightItems);
    // Clear the selection in the left list
    resetLeftSelection();
  };
  const createGroup = async (data: groupNameInterface) => {
    setCreatingGroup(true);
    const customerId = rightItems.map((item) => item.id);
    const payload = {
      companyCustomerGroupName: data.groupName,
      companyId,
      customerType: 'Corporate Customer',
      customerId,
      actionName: 'CreateCustomerGroup',
    };
    const response = await universalPostRequest(
      APIEndpoint.createIndividualCustomerGroup,
      payload
    );
    setCreatingGroup(false);
    setRightItems([]);
    setLeftItems(usersList);
    setShowModal(false);
    reset();
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Box display="flex" flexDirection="column" height="100%" gap={2}>
            {/* Left List */}
            <Typography variant="body1" fontWeight="bold" color="textDisabled">
              Corporate Users
            </Typography>
            <SelectableList
              items={leftItems}
              selectedItems={selectedLeftItems}
              toggleItem={toggleLeftItem}
              searchVisible={true}
              labelKey="name"
              idKey="id"
              loading={loading}
            />
          </Box>
        </Grid>

        {/* Arrow Button */}
        <Grid
          item
          xs={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CustomIconButton
            tooltipTitle="Move selected users"
            buttonType="primary"
            onClick={handleMoveItemsToRight} // Move items on click
          >
            <ForwardIcon color="primary" fontSize="large" />
          </CustomIconButton>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" flexDirection="column" height="100%" gap={2}>
            {/* Right List */}
            <Typography variant="body1" fontWeight="bold" color="textDisabled">
              Group members
            </Typography>
            <SelectableList
              items={rightItems}
              selectedItems={selectedRightItems}
              toggleItem={toggleRightItem}
              searchVisible={true}
              idKey="id"
              showCheckBoxIcon={false}
              labelKey="name"
              showDivider={true}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <GradientButton
              disabled={!rightItems.length}
              onClick={() => setShowModal(true)}
            >
              Create group
            </GradientButton>
          </Box>
        </Grid>
      </Grid>

      <CustomModal
        primaryButtonAction={handleSubmit(createGroup)}
        primaryButtonLabel="Create group"
        autoClose={false}
        open={showModal}
        primaryButtonLoading={creatingGroup}
        type="info"
        onClose={() => {
          setShowModal(false);
          reset();
        }}
        title="Corporate group name"
      >
        <form onSubmit={handleSubmit(createGroup)}>
          <Typography variant="body2" color="textDisabled">
            {' '}
            Type the group name
          </Typography>
          <InputElement
            name="groupName"
            size="small"
            control={control}
            label="Group name"
            autoFocus
            placeholder="Corporate group name"
            type="text"
            rules={{ required: true }} // Rules can be added here
          />
        </form>
      </CustomModal>
    </>
  );
};

export default CreateCorporateGroup;
