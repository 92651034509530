import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { RootState } from '../redux/store'; // Adjust the path according to your store

const RedirectIfAuthenticated: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const { accessToken, companyId } = useSelector(
    (state: RootState) => state.auth
  );
  const url = `/${companyId}/dashboard`;

  // If the user has an access token, redirect to the specified route
  if (accessToken) {
    return <Navigate to={url} />;
  }

  return children; // Render children if no access token
};

export default RedirectIfAuthenticated;
