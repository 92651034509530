import React from 'react';

import {
  Box,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { green, orange, red } from '@mui/material/colors';

import GradientButton from './elements/GradientButton';

// Define the types for the modal component
type ModalType = 'success' | 'warning' | 'info' | 'error';

// Define the props for the modal component
interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  type: ModalType;
  title?: string;
  primaryButtonLabel: string;
  primaryButtonAction: (e?: any) => void;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
  primaryButtonLoading?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  autoClose?: boolean;
  primaryButtonDisabled?: boolean;
}

// Define colors based on the type prop
const typeStyles: Record<
  ModalType,
  { background: string; borderColor: string }
> = {
  success: {
    background: green[400], // Green
    borderColor: green[400],
  },
  warning: {
    background: orange[400], // Orange
    borderColor: orange[400],
  },
  info: {
    background: '#2196F3', // Blue
    borderColor: '#2196F3',
  },
  error: {
    background: red[400], // Red
    borderColor: red[400],
  },
};

const CustomModal: React.FC<React.PropsWithChildren<CustomModalProps>> = ({
  open,
  onClose,
  type,
  title,
  children,
  primaryButtonLabel,
  primaryButtonAction,
  secondaryButtonLabel,
  secondaryButtonAction,
  primaryButtonLoading = false,
  maxWidth = 'sm',
  autoClose = true,
  primaryButtonDisabled = false,
}) => {
  const { background, borderColor } = typeStyles[type];

  const handleClose = (
    event: object,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    // Only close the modal if autoClose is true or if the reason is not backdrop click
    if (autoClose || reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '10px', // Rounded corners for the entire dialog
          border: `1px solid ${borderColor}`, // Border around the entire modal
        },
      }}
    >
      {/* Header with Title and Close Button */}
      {title && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Align close button to the right
            alignItems: 'center',
            backgroundColor: background,
            paddingLeft: '12px',
            color: 'white',
            borderRadius: '8px 8px 0 0', // Rounded top corners
            p: 1,
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ textAlign: 'left' }}
          >
            {title}
          </Typography>

          {/* Close Button */}
        </Box>
      )}

      {/* Modal Content */}
      <DialogContent
        sx={{
          padding: '5px',
          paddingLeft: '12px', // Ensure padding for content
          marginTop: 1,
        }}
      >
        {children}
      </DialogContent>

      {/* Modal Actions (Primary & Secondary Buttons) */}
      <DialogActions sx={{ padding: '2rem', gap: 4 }}>
        <GradientButton
          rounded
          variant="contained"
          disabled={primaryButtonDisabled}
          loading={primaryButtonLoading}
          onClick={() => {
            primaryButtonAction();
            autoClose && onClose();
          }}
          sx={{ marginLeft: 'auto' }}
        >
          {primaryButtonLabel}
        </GradientButton>
        {secondaryButtonLabel && secondaryButtonAction && (
          <Button
            sx={{ borderRadius: 50 }}
            variant="text"
            onClick={() => {
              secondaryButtonAction();
              onClose();
            }}
          >
            {secondaryButtonLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
