import { useState } from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';

import HeaderComp from '../../components/elements/HeadingComponent';
import CreateCorporateGroup from './CreateCorporateGroup';
import CreateIndividualGroup from './CreateIndividualGroup';
import DeleteCustomerGroupMember from './DeleteCustomerGroupMember';
import DeleteCustomerGroup from './DeleteIndividualCustomerGroup';

const CustomerGroup = () => {
  const [groupType, setGroupType] = useState('');
  return (
    <>
      <HeaderComp>Customer group</HeaderComp>
      <Grid container mb={4}>
        <Grid item lg={12}>
          <Box
            sx={{
              borderRadius: 4,
              p: 2,
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
            }}
          >
            <FormControl>
              <FormLabel id="row-radio-buttons-group-label">
                Select the operation
              </FormLabel>

              <RadioGroup
                onChange={(e) => setGroupType(e.target.value)}
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Create individual group"
                  control={<Radio size="small" />}
                  label="Create individual group"
                />
                <FormControlLabel
                  value="Create corporate group"
                  control={<Radio size="small" />}
                  label="Create corporate group"
                />
                <FormControlLabel
                  value="Delete group"
                  control={<Radio size="small" color="error" />}
                  label="Delete customer group"
                />
                <FormControlLabel
                  value="Delete member"
                  control={<Radio size="small" color="error" />}
                  label="Delete customer group member"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      {groupType === 'Create individual group' && <CreateIndividualGroup />}
      {groupType === 'Create corporate group' && <CreateCorporateGroup />}
      {groupType === 'Delete group' && <DeleteCustomerGroup />}
      {groupType === 'Delete member' && <DeleteCustomerGroupMember />}
    </>
  );
};

export default CustomerGroup;
