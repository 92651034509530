// src/encryptedStorage.ts
import CryptoJS from 'crypto-js';
import sessionStorage from 'redux-persist/lib/storage/session'; // Default storage for web

const SECRET_KEY = 'your_secret_key'; // Use a strong secret key

const encryptData = (data: any): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decryptData = (data: string): any => {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const encryptedStorage = {
  setItem: async (key: string, value: any) => {
    const encryptedValue = encryptData(value);
    await sessionStorage.setItem(key, encryptedValue);
  },
  getItem: async (key: string) => {
    const encryptedValue = await sessionStorage.getItem(key);
    return encryptedValue ? decryptData(encryptedValue) : null;
  },
  removeItem: async (key: string) => {
    await sessionStorage.removeItem(key);
  },
};

export default encryptedStorage;
