// src/components/GradientCard.tsx
import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface SelectionCardProps {
  children: React.ReactNode;
  isSelected?: boolean; // Optional prop for selected state
  showBorderColor?: boolean;
}

// Pass props into the styled function
const SelectionCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected', // Ensure `isSelected` is not forwarded to the DOM
})<{ isSelected?: boolean; showBorderColor?: boolean }>(
  ({ theme, isSelected, showBorderColor }) => ({
    background: isSelected
      ? 'linear-gradient(45deg, #42d392, #647eff)' // Gradient background when selected
      : '#fff', // White background when not selected
    borderRadius: '12px',
    padding: '.2rem 1rem',
    color: isSelected ? '#fff' : theme.palette.text.primary, // Text color adjusts based on selection
    border: isSelected || !showBorderColor ? 'none' : '1px solid #647eff', // Blue border when not selected
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out, background 0.3s ease-in-out', // Smooth background transition
    cursor: 'pointer',

    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)',
    },
  })
);

const SelectionCardComponent: React.FC<SelectionCardProps> = ({
  children,
  isSelected = false, // Default to false if not provided
}) => {
  return <SelectionCard isSelected={isSelected}>{children}</SelectionCard>;
};

export default SelectionCardComponent;
