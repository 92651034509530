import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { CustomerProfile } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import GradientButton from '../../../components/elements/GradientButton';
import HeaderComp from '../../../components/elements/HeadingComponent';
import InputElement from '../../../components/elements/InputElement';
import { validationInviteCustomer } from './validation';

const InviteCustomer = () => {
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomerProfile>({
    resolver: yupResolver(validationInviteCustomer),
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone_number: '',
    } as CustomerProfile,
  });
  const submitHandler = async (data: CustomerProfile) => {
    setLoading(true);
    const response = await universalPostRequest(
      APIEndpoint.inviteIndividualCustomer,
      {
        email: data.email,
        phone_number: data.phone_number,
        userType: 'Individual',
        firstName: data.firstName,
        lastName: data.lastName,
        companyId: uuid,
      }
    );

    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
    }
    reset({} as CustomerProfile);
    setLoading(false);
  };
  return (
    <>
      <HeaderComp> Invite customer</HeaderComp>

      <Box display="flex" justifyContent="center" width="100%">
        <Box
          mt={5}
          // minWidth="50%"
          width="100%"
          sx={{ boxShadow: '5px 10px 20px rgba(0, 0, 0, 0.1)' }}
          p={5}
          borderRadius={8}
        >
          <legend>
            <Typography variant="subtitle2">User details</Typography>
          </legend>
          <form onSubmit={handleSubmit(submitHandler)} autoComplete="off">
            <InputElement
              name="firstName"
              control={control}
              label="First name"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            <InputElement
              name="lastName"
              control={control}
              label="Last name"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />

            <InputElement
              name="email"
              control={control}
              label="Email"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            <InputElement
              name="phone_number"
              control={control}
              label="Phone"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            {/* <PhoneNumberInput
              control={control}
              name="phone_number"
              label="Phone"
            /> */}
            <Box textAlign="right" mt={4}>
              <GradientButton type="submit" rounded loading={loading}>
                Invite customer
              </GradientButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default InviteCustomer;
