import React from 'react';

import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const popupNotification = (
  message: string | React.ReactElement,
  success: boolean,
  description?: string
) => {
  const commonOptions: ToastOptions = {
    position: 'top-right', // Manually specifying the position
    autoClose: 5000, // 5 seconds
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };

  if (success) {
    toast.success(<strong>{message}</strong>, commonOptions);
  } else {
    toast.error(
      <div>
        <strong>{message}</strong>
        {description && <p>{description}</p>}
      </div>,
      commonOptions
    );
  }
};

// Place this somewhere in your app component
export const ToastNotificationContainer = () => {
  return <ToastContainer />;
};
