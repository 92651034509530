import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Container, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';

import CustomModal from '../../../components/CustomModal';
import GradientButton from '../../../components/elements/GradientButton';
import InputElement from '../../../components/elements/InputElement';
import { forgotPasswordSchema } from '../utils';

export interface forgotPassword {
  username: string;
}
interface Props {
  setIsOTPReceived: React.Dispatch<React.SetStateAction<boolean>>;
}

const GetForgotPassword: React.FC<Props> = ({ setIsOTPReceived }) => {
  const { username } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<forgotPassword>({
    resolver: yupResolver(forgotPasswordSchema),
    mode: 'onChange',
  });
  const onSubmit: SubmitHandler<forgotPassword> = async (data) => {
    setLoading(true);
    const response = await universalPostRequest(APIEndpoint.forgotPassword, {
      email: data.username,
    });
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setResponseMessage(response.message);
      // setIsOTPReceived(true);
    }
    setShowModal(true);
    setLoading(false);
  };
  return (
    <React.Fragment>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <Box
          minWidth="50%"
          sx={{
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
          }}
        >
          <Typography variant="h5" component="h1">
            Forgot password
          </Typography>

          <Box gap={4} mt={5} width="100%">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <InputElement
                name="username"
                control={control}
                placeholder="Type your username or email"
                autoFocus
                label="Username or Email"
                required
                type="text"
                rules={{ required: true }}
              />
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="text" onClick={() => navigate('/login')}>
                  Go back
                </Button>
                <GradientButton type="submit" rounded loading={loading}>
                  Get OTP
                </GradientButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Container>
      <CustomModal
        title="Forgot password"
        type={responseMessage.length ? 'success' : 'error'}
        open={showModal}
        primaryButtonLabel={responseMessage.length ? 'Login' : 'Try again'}
        primaryButtonAction={() => {
          navigate('/login');
        }}
        onClose={() => {
          setShowModal(false);
          setResponseMessage('');
        }}
      >
        <Typography variant="body2">{responseMessage}</Typography>
      </CustomModal>
    </React.Fragment>
  );
};

export default GetForgotPassword;
