import * as yup from 'yup';

export const validationInviteCustomer = yup.object({
  firstName: yup
    .string()
    .matches(
      new RegExp("^[\\p{L}][\\p{L}\\d.'’\\- ]*[\\p{L}\\d]$", 'u'),
      'First name must start and end with an alphabet, and can only contain letters, spaces, apostrophes, dots, and hyphens.'
    )
    .max(50, 'Max length is 50 characters')
    .trim() // Removes leading/trailing spaces
    .required('First name is required'),
  lastName: yup
    .string()
    .matches(
      new RegExp("^[\\p{L}][\\p{L}\\d.'’\\- ]*[\\p{L}\\d]$", 'u'),
      'Last name must start and end with an alphabet, and can only contain letters, spaces, apostrophes, dots, and hyphens.'
    )
    .max(50, 'Max length is 50 characters')
    .trim() // Removes leading/trailing spaces
    .required('Last name is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  phone_number: yup
    .string()
    .matches(/^\d+$/, 'Phone must contain only digits with no spaces')
    .min(5, 'Phone must be at least 5 characters')
    .required('Phone is required'),
});

export const validateInviteCorporateUser = yup.object().shape({
  firstName: yup
    .string()
    .matches(
      new RegExp("^[\\p{L}][\\p{L}\\d.'’\\- ]*[\\p{L}\\d]$", 'u'),
      'First name must start and end with an alphabet, and can only contain letters, spaces, apostrophes, dots, and hyphens.'
    )
    .max(50, 'Max length is 50 characters')
    .trim() // Removes leading/trailing spaces
    .required('First name is required'),
  lastName: yup
    .string()
    .matches(
      new RegExp("^[\\p{L}][\\p{L}\\d.'’\\- ]*[\\p{L}\\d]$", 'u'),
      'Last name must start and end with an alphabet, and can only contain letters, spaces, apostrophes, dots, and hyphens.'
    )
    .max(50, 'Max length is 50 characters')
    .trim() // Removes leading/trailing spaces
    .required('Last name is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  phone_number: yup
    .string()
    .matches(/^\d+$/, 'Phone must contain only digits with no spaces')
    .min(6, 'Phone must be at least 6 characters')
    .required('Phone is required'),
  corporateName: yup
    .string()
    .matches(
      new RegExp("^[\\p{L}\\d][\\p{L}\\d.'’\\- ]*[\\p{L}\\d]$", 'u'),
      'Corporate name must start and end with an alphabet, and can only contain letters, spaces, apostrophes, dots, and hyphens.'
    )
    .required('Corporate name is required'),
  // inviteType: yup.string(),
  corporate: yup.string().required('Corporate name is required'),
});
