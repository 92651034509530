import React from 'react';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Define the shape of the data to be passed as props
interface ChartData {
  fileCount: string;
  monYear: string;
}

// Define the props for the BarChart component
interface BarChartProps {
  data: ChartData[]; // Array of ChartData
  label: string;
  barText: string;
  backgroundColor: string;
  borderColor: string;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  label,
  barText,
  backgroundColor,
  borderColor,
}) => {
  const chartData = {
    labels: data.map((item) => item.monYear), // x-axis labels
    datasets: [
      {
        label: label,
        data: data.map((item) => parseInt(item.fileCount)), // y-axis data
        backgroundColor: backgroundColor, // Bar color
        borderColor: borderColor,
        borderWidth: 1,
      },
    ],
  };

  // Options for the chart
  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top', // Legend position
      },
      title: {
        display: true,
        text: barText,
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Ensures the y-axis starts at 0
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;
