import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalGetRequest } from 'common/requestHandler';
import BarChart from 'components/charts/BarCharts';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const dailyStat = [
  {
    fileCount: '23',
    monYear: '2024-10-31',
    filed_created_today: 'None',
  },
  {
    fileCount: '49',
    monYear: '2024-11-07',
    filed_created_today: '26',
  },
  {
    fileCount: '49',
    monYear: '2024-11-08',
    filed_created_today: '0',
  },
  {
    fileCount: '49',
    monYear: '2024-11-09',
    filed_created_today: '0',
  },
];

interface analyticsData {
  dailyStats: any[];
  monthlyStats: any[];
}
const AnalyticsScreen = () => {
  const { companyId } = useSelector((state: RootState) => state.auth);
  const [data, setData] = useState({} as analyticsData);
  const getAnalyticsData = async () => {
    const response: any = await universalGetRequest(APIEndpoint.getAnalytics, {
      companyId,
    });
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      const analyticsResponse: analyticsData = response.data;
      setData(analyticsResponse);
    }
  };
  useEffect(() => {
    getAnalyticsData();
  }, []);
  console.table(data);
  return (
    <Grid container spacing={5}>
      <Grid item lg={6} md={12}>
        <BarChart
          data={data.monthlyStats || []}
          label="File count"
          barText="Files count per month"
          backgroundColor="rgba(75, 192, 192, 0.6)"
          borderColor="rgba(75, 192, 192, 1)"
        />
      </Grid>
      <Grid item lg={6} md={12}>
        <BarChart
          data={data.dailyStats || []}
          label="File count"
          barText="Files uploaded per day"
          backgroundColor="#89CFF0"
          borderColor="#6CB4EE"
        />
      </Grid>
    </Grid>
  );
};

export default AnalyticsScreen;
