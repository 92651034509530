import axios from 'axios';
import { APIEndpoint } from 'common/enum';
import {
  allowedFileTypes,
  defaultErrorMessage,
  MAX_FILE_SIZE_MB,
} from 'config/config';
import { popupNotification } from 'notifications/ToastNotification';
export const handleFileUpload = async (
  uuid: string | undefined,
  folderId: string | null,
  firstName: string | null,
  lastName: string | null,
  uploadedFiles: File[],
  newFolderPath: string,
  setModalOpen: (isOpen: boolean) => void,
  setUploadedFiles: (files: File[]) => void,
  setUploading: (uploading: boolean) => void,
  setProgress: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >,
  accessToken: string | null
) => {
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
  setUploading(true);

  const uploadPromises = uploadedFiles.map(async (file) => {
    if (file.size > MAX_FILE_SIZE_BYTES) {
      popupNotification(
        `File ${file.name} is too large. Maximum allowed size is ${MAX_FILE_SIZE_MB}MB.`,
        false
      );
      return; // Skip uploading this file
    }
    if (!allowedFileTypes.includes(file.type)) {
      popupNotification(`File ${file.name} is not a valid file type.`, false);
      return; // Skip uploading this file
    }
    try {
      const { data } = await axios.post(
        APIEndpoint.uploadFile,
        {
          fileName: `${uuid}/${folderId}/Home/${
            newFolderPath.length > 0 ? newFolderPath + '/' : ''
          }${file.name}`,
          uploadedBy: firstName + ' ' + lastName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );

      const uploadUrl = data.upload_url.url;

      const formData = new FormData();
      if (data.upload_url.fields) {
        Object.entries(data.upload_url.fields).forEach(([k, v]: any) => {
          formData.append(k, v);
        });
      } else {
        console.error('No fields found in the response');
      }

      formData.append('file', file);

      await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total ?? progressEvent.loaded;
          const progressPercentage = Math.round(
            (progressEvent.loaded / total) * 100
          );
          setProgress((prevProgress: { [key: string]: number }) => ({
            ...prevProgress,
            [file.name]: progressPercentage,
          }));
        },
      });
      popupNotification('Files uploaded successfully', true);
    } catch (error) {
      popupNotification(defaultErrorMessage, false);
    }
  });

  await Promise.all(uploadPromises).then(() => {
    // popupNotification('Files uploaded successfully', true);
    setModalOpen(false);
    setUploadedFiles([]);
    setUploading(false);
  });
};
