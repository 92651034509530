import { useNavigate, useParams } from 'react-router-dom';

function useNavigateWithUUID() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const navigateWithUuid = (path: string) => {
    navigate(`/${uuid}/${path}`); // Prepend uuid to the path
  };
  const generateUrlWithUuid = (path: string) => {
    return uuid ? `/${uuid}/${path}` : `/${path}`; // Prepend uuid to the path
  };

  return { navigateWithUuid, generateUrlWithUuid };
}
export default useNavigateWithUUID;
