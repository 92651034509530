import React from 'react';

import { Typography, TypographyProps } from '@mui/material';

// Define your custom Header component
const HeaderComp: React.FC<TypographyProps> = (props) => {
  return (
    <Typography
      color="primary" // Default color for the header, can be overridden
      variant="h5"
      mb={5}
      sx={{
        background: 'linear-gradient(45deg, #42d392, #647eff)', // Gradient colors
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text', // Add this for Firefox support
        display: 'inline-block', // Ensure the gradient is only applied to the text
        fontWeight: 'bold',
      }}
      {...props} // Spread all Typography props (e.g., variant, color, align, etc.)
    >
      {props.children} {/* Render any children inside the Typography */}
    </Typography>
  );
};

export default HeaderComp;
