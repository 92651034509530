import * as yup from 'yup';

export const validationInviteAdmin = yup.object({
  firstName: yup
    .string()
    .matches(
      new RegExp("^[\\p{L}\\d][\\p{L}\\d.'’\\- ]*[\\p{L}\\d]$", 'u'),
      'First name must start and end with an alphabet, and can only contain letters, spaces, apostrophes, dots, and hyphens.'
    )
    .max(50, 'Max length is 50 characters')
    .trim() // Removes leading/trailing spaces
    .required('First name is required'),
  lastName: yup
    .string()
    .matches(
      new RegExp("^[\\p{L}\\d][\\p{L}\\d.'’\\- ]*[\\p{L}\\d]$", 'u'),
      'Last name must start and end with an alphabet, and can only contain letters, spaces, apostrophes, dots, and hyphens.'
    )
    .max(50, 'Max length is 50 characters')
    .trim() // Removes leading/trailing spaces
    .required('Last name is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  phone_number: yup
    .string()
    .matches(/^\d+$/, 'Phone must contain only digits with no spaces')
    .min(6, 'Phone must be at least 6 characters')
    .required('Phone is required'),
  companyName: yup
    .string()
    .matches(
      new RegExp("^[\\p{L}\\d][\\p{L}\\d.'’\\- ]*[\\p{L}\\d]$", 'u'),
      'Company name can only contain alphabets, apostrophes, and spaces'
    )
    .min(5, 'Company name must be at least 5 characters long')
    .required('Company name is required'),
});

export const validateUsernameOrEmail = yup
  .string()
  .test(
    'username-or-email',
    'Must be a valid username or email',
    function (value) {
      const usernameRegex = /^[A-Za-z0-9][A-Za-z0-9-_.]*$/;
      const emailNoApostrophe = /^[^\s@']+@[^\s@]+\.[^\s@']+$/;

      const isEmail = yup
        .string()
        .email('Invalid email id')
        .test('no-apostrophe', 'Email cannot contain apostrophes', (value) =>
          emailNoApostrophe.test(value || '')
        )
        .isValidSync(value || '');

      return usernameRegex.test(value || '') || isEmail;
    }
  )
  .required('Username or email is required');
export const folderNameRegex = /^[\w\-. &]+$/;
