import { userTypes } from 'config/config';
import PageNotFound from 'pages/404';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import { Route, Routes } from 'react-router-dom';
import AccessControl from 'screens/accessControl/AccessControl';
import CreateCustomerGroup from 'screens/accessControl/CustomerGroup';
import Mappings from 'screens/accessControl/Mapping';
import StaffGroups from 'screens/accessControl/StaffGroups';
import AdminDashboard from 'screens/admin/AdminDashboard';
import Invite from 'screens/admin/invite/Invite';
import InviteCorporate from 'screens/admin/invite/InviteCorporate';
import InviteCustomer from 'screens/admin/invite/InviteCustomer';
import InviteStaffUser from 'screens/admin/invite/InviteStaffuser';
import PreferenceAdmin from 'screens/admin/PreferenceAdmin';
import AnalyticsScreen from 'screens/analytics/Analytics';
import ContractManagement from 'screens/contractManagement/ContractManagement';
import ChangePassword from 'screens/password/ChangePassword';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const AdminRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.admin]}>
    <Layout>
      <Routes>
        <Route path="dashboard" element={<AnalyticsScreen />} />
        <Route path="documents/*" element={<AdminDashboard />} />
        <Route path="invite-user" element={<Invite />} />
        <Route
          path="invite-user/invite-customer"
          element={<InviteCustomer />}
        />
        <Route path="invite-user/invite-staff" element={<InviteStaffUser />} />
        <Route
          path="invite-user/invite-corporate"
          element={<InviteCorporate />}
        />
        <Route path="preference" element={<PreferenceAdmin />} />
        <Route path="access-control" element={<AccessControl />} />
        <Route path="access-control/staff-groups" element={<StaffGroups />} />
        <Route
          path="access-control/create-customer-group"
          element={<CreateCustomerGroup />}
        />

        <Route path="access-control/mappings" element={<Mappings />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/contract-management" element={<ContractManagement />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default AdminRoutes;
