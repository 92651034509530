import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { APIEndpoint } from 'common/enum';
import { customerGroupInterface } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import CustomModal from 'components/CustomModal';
import SelectableList from 'components/ListItemsWithSearch';
import useSelectableItems from 'hooks/useSelectableList';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { getCustomerGroups } from './utils';

//  This component deletes individual customers as well as corporate groups
const DeleteCustomerGroup = () => {
  const [customerGroups, setCustomerGroups] = useState(
    [] as customerGroupInterface[]
  );
  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState(
    {} as customerGroupInterface
  );
  const [staffGroupMembers, setStaffGroupMembers] = useState([] as string[]);
  const { selectedItems, toggleItem, resetSelection } = useSelectableItems();
  const [loading, setLoading] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { companyId } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    getCustomerGroups(companyId, setCustomerGroups, setLoading);
  }, []);
  useEffect(() => {
    const filteredGroup = customerGroups.filter(
      (group) => group.companyCustomerGroupName == selectedItems[0]
    );
    setSelectedCustomerGroup(filteredGroup[0]);
    setStaffGroupMembers(filteredGroup.flatMap((group) => group.customerIds));
  }, [selectedItems]);
  const deleteGroup = async () => {
    setDeletingGroup(true);
    const payload = {
      companyCustomerGroupName: selectedCustomerGroup.companyCustomerGroupName,
      companyId: selectedCustomerGroup.companyId,
      actionName: 'DeleteCustomerGroup',
    };
    const response = await universalPostRequest(
      APIEndpoint.deleteGroup,
      payload
    );
    setDeletingGroup(false);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
      getCustomerGroups(companyId, setCustomerGroups, setLoading);
      setStaffGroupMembers([]);
      setSelectedCustomerGroup({} as customerGroupInterface);
    }
  };
  return (
    <>
      <Typography variant="body1" mb={5} color="error">
        Delete customer group
      </Typography>
      <Grid container spacing={4}>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Customer groups
          </Typography>
          <SelectableList
            items={customerGroups}
            selectedItems={selectedItems}
            toggleItem={toggleItem}
            searchVisible={true}
            showSelectHighlight
            showCheckBoxIcon={false}
            allowSingleSelection
            loading={loading}
            idKey="companyCustomerGroupName" // Nested key
            labelKey="companyCustomerGroupName" // Nested label
            height={150}
          />
        </Grid>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Customer group members
          </Typography>
          <Box
            height="100%"
            sx={{
              borderRadius: 4,
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <List>
              {staffGroupMembers.map((member, index) => (
                <>
                  <ListItem key={member}>
                    <Typography variant="body2">{member}</Typography>
                  </ListItem>
                  {index !== staffGroupMembers.length - 1 && <Divider />}
                </>
              ))}
              {!staffGroupMembers.length && (
                <Typography variant="body1" textAlign="center" color={red[400]}>
                  No members
                </Typography>
              )}
            </List>
          </Box>
        </Grid>
        <Grid lg={12}>
          <Box display="flex" justifyContent="flex-end" mt={8}>
            <Button
              variant="contained"
              color="error"
              disabled={selectedItems.length === 0}
              onClick={() => setShowModal(true)}
            >
              Delete group
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        type="error"
        open={showModal}
        onClose={() => setShowModal(false)}
        primaryButtonAction={deleteGroup}
        primaryButtonLabel="Delete group"
        secondaryButtonAction={() => setShowModal(false)}
        secondaryButtonLabel="Cancel"
        primaryButtonLoading={deletingGroup}
        title={`Delete group: ${selectedItems[0]}`}
      >
        <Typography variant="body1">
          Customer group {selectedItems[0]} will be
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: red[400] }}
            component="span"
          >
            {' '}
            Deleted{' '}
          </Typography>
          along with all its members.
        </Typography>
        <Typography variant="body1">Do you want to continue ?</Typography>
      </CustomModal>
    </>
  );
};

export default DeleteCustomerGroup;
