import HeaderComp from 'components/elements/HeadingComponent';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import SetupContractManagement from './signup/SetupContractManagement';

const ContractManagement = () => {
  const { isSignVerifyEnabled } = useSelector((state: RootState) => state.auth);
  return (
    <>
      <HeaderComp>Contract management</HeaderComp>
      {!isSignVerifyEnabled && <SetupContractManagement />}
    </>
  );
};

export default ContractManagement;
