import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { store } from '../redux/store'; // Adjust the import path

// Define the function type for HTTP methods
type HttpMethod = 'get' | 'post' | 'put' | 'delete';

interface RequestConfig<T> {
  url: string;
  method: HttpMethod;
  data?: any; // For POST and PUT requests
  params?: any; // For GET requests
  headers?: { [key: string]: string }; // Custom headers
}

// Define a type for the error response
interface ErrorResponse {
  error: string;
}

// Generic structure for the expected response
interface ApiResponse<T> {
  message: string;
  data: T;
}

interface ApiResponseWithMessage<T> {
  data: T;
  message: string;
}

// The function now returns ApiResponseWithMessage<T>
const universalRequest = async <T>({
  url,
  method,
  data,
  params,
  headers,
}: RequestConfig<T>): Promise<ApiResponseWithMessage<T> | ErrorResponse> => {
  const config: AxiosRequestConfig = {
    url,
    method,
    data,
    params,
    headers: {
      ...headers,
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  try {
    const response: AxiosResponse<ApiResponse<T>> = await axios(config);

    // Check if the response status is 200
    if (response.status === 200) {
      const { message, data } = response.data;
      // Return the extracted message and data
      return {
        data,
        message,
      };
    } else {
      // Handle non-200 responses by sending the error from the backend
      const errorMessage =
        response.data?.message || 'Unexpected error occurred';
      return { error: errorMessage };
    }
  } catch (error) {
    // Handle and return error responses
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const message =
        axiosError.response?.data &&
        typeof axiosError.response.data === 'object'
          ? (axiosError.response.data as any).message
          : 'An unexpected error occurred';

      // Log detailed error for debugging
      console.error('HTTP Request Error:', {
        url,
        method,
        data,
        params,
        headers,
        error: axiosError.toJSON(),
      });

      // Provide user-friendly error message
      return { error: message };
    }

    // Handle unexpected errors
    console.error('Unexpected Error:', error);
    return { error: 'An unexpected error occurred' };
  }
};

// Example methods for convenience
export const universalGetRequest = <T>(
  url: string,
  params?: any,
  headers?: { [key: string]: string }
) => universalRequest<T>({ url, method: 'get', params, headers });

export const universalPostRequest = <T>(
  url: string,
  data?: any,
  headers?: { [key: string]: string }
) => universalRequest<T>({ url, method: 'post', data, headers });

export const universalPutRequest = <T>(
  url: string,
  data?: any,
  headers?: { [key: string]: string }
) => universalRequest<T>({ url, method: 'put', data, headers });

export const universalDeleteRequest = <T>(
  url: string,
  params?: any,
  headers?: { [key: string]: string }
) => universalRequest<T>({ url, method: 'delete', params, headers });

// Helper function to get the access token
function getAccessToken(): string {
  const { accessToken }: { accessToken: string | null } = store.getState().auth;
  return accessToken ? accessToken : '';
}
function getIdToken(): string {
  const { idToken }: { idToken: string | null } = store.getState().auth;
  return idToken ? idToken : '';
}
