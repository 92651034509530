import { useState } from 'react';

import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import landingImage from '../assets/images/landing.jpg';
import logo from '../assets/images/logo.png';
import CustomModal from '../components/CustomModal';
import GradientButton from '../components/elements/GradientButton';

const LandingPage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  return (
    <Box bgcolor="white" height="100vh" width="100%">
      {/* Navigation Bar */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box flex={1}>
            <img src={logo} height={100} width="auto" alt="Company Logo" />
          </Box>
          <Box>
            <Button variant="text" onClick={() => navigate('login')}>
              Sign in
            </Button>
          </Box>
          <Box>
            <Button variant="text" onClick={() => setShowModal(true)}>
              Contact us
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Hero Unit */}
      <Container
        maxWidth="lg"
        component="main"
        sx={{
          pt: 0,
          pb: 0,
          //   height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container spacing={3} alignItems="center" sx={{ flexGrow: 1 }}>
          <Grid item xs={12} md={7} lg={6}>
            <Typography
              component="h1"
              variant="h2"
              color="text.primary"
              gutterBottom
            >
              Sharing docs made simple
            </Typography>
            <Typography variant="h5" color="text.secondary" paragraph>
              An end-to-end platform that helps you share documents securely.
            </Typography>
            <Box>
              <GradientButton onClick={() => navigate('login')}>
                Start Sharing
              </GradientButton>
              {/* <Button variant="contained" color="primary" sx={{ my: 1 }}>
                Start Sharing
              </Button> */}
              <Button
                variant="outlined"
                color="primary"
                sx={{ my: 1, mx: 1.5 }}
              >
                How it works
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} lg={6}>
            <Box />
            <img
              style={{ objectFit: 'contain', width: '100%' }}
              src={landingImage}
            />
          </Grid>
        </Grid>
        <CustomModal
          open={showModal}
          onClose={() => setShowModal(false)}
          type="info"
          title="Contact us"
          primaryButtonLabel="Ok"
          primaryButtonAction={() => setShowModal(false)}
        >
          <Typography variant="h6">Email: sales@tasalli.com</Typography>
        </CustomModal>
      </Container>
    </Box>
  );
};

export default LandingPage;
