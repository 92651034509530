import CSVIcon from '../../assets/icons/CSV.svg';
import DOCIcon from '../../assets/icons/DOCX.svg';
import JPGIcon from '../../assets/icons/JPG.svg';
import PDFIcon from '../../assets/icons/PDF.svg';
import PNGIcon from '../../assets/icons/PNG.svg';
import PPTIcon from '../../assets/icons/PPT.svg';
import PUBIcon from '../../assets/icons/PUB.svg';
import DefaultIcon from '../../assets/icons/RAW.svg';
import TXTIcon from '../../assets/icons/TXT.svg';
import XSLIcon from '../../assets/icons/XSL.svg';
interface Props {
  fileType:
    | 'pdf'
    | 'png'
    | 'jpg'
    | 'jpeg'
    | 'docx'
    | 'doc'
    | 'txt'
    | 'csv'
    | 'xsl'
    | 'ppt'
    | 'pub';
}

const RenderFileIcon = ({ fileType }: { fileType: string }) => {
  const renderIcon = () => {
    switch (fileType) {
      case 'pdf':
        return <img src={PDFIcon} alt="pdf" height={40} />;
      case 'doc':
        return <img src={DOCIcon} alt="doc" height={50} />;
      case 'docx':
        return <img src={DOCIcon} alt="docx" height={50} />;
      case 'jpg':
        return <img src={JPGIcon} alt="jpg" height={50} />;
      case 'jpeg':
        return <img src={JPGIcon} alt="jpeg" height={50} />;
      case 'png':
        return <img src={PNGIcon} alt="png" height={50} />;
      case 'txt':
        return <img src={TXTIcon} alt="txt" height={50} />;
      case 'csv':
        return <img src={CSVIcon} alt="csv" height={50} />;
      case 'xsl':
        return <img src={XSLIcon} alt="xsl" height={50} />;
      case 'xlsx':
        return <img src={XSLIcon} alt="xsl" height={50} />;
      case 'ppt':
        return <img src={PPTIcon} alt="ppt" height={50} />;
      case 'pub':
        return <img src={PUBIcon} alt="pub" height={50} />;

      default:
        return <img src={DefaultIcon} alt="Default image" height={50} />;
    }
  };
  return <span>{renderIcon()}</span>;
};

export default RenderFileIcon;
