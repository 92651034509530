import { useState } from 'react';

// Custom hook for managing selected items
const useSelectableItems = (initialSelectedItems: string[] = []) => {
  const [selectedItems, setSelectedItems] =
    useState<string[]>(initialSelectedItems);

  // Toggle item selection
  const toggleItem = (item: string) => {
    setSelectedItems(
      (prevSelectedItems) =>
        prevSelectedItems.includes(item)
          ? prevSelectedItems.filter((i) => i !== item) // Remove item if already selected
          : [...prevSelectedItems, item] // Add item if not selected
    );
  };

  // Reset selection
  const resetSelection = () => setSelectedItems([]);

  return { selectedItems, toggleItem, resetSelection };
};

export default useSelectableItems;
