import { APIEndpoint } from 'common/enum';
import { userTypesInterface } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
export const name = 'asd';

export interface validateMFAInterface {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
}

export interface idTokenInterface {
  authTime: number;
  phoneNumber: string;
  email: string;
  username: string;
  employeeId: string;
  companyId: string;
  emailVerified: boolean;
}

export interface JWTDecodedIDToken {
  auth_time: number;
  'custom:employee-id': string;
  'custom:customer-id': string;
  'custom:company-id': string;
  'custom:corporate-id': string;
  phone_number: string;
  email: string;
  username: string;
  email_verified: boolean;
  'custom:user-type': userTypesInterface;
  given_name: string;
  family_name: string;
  'custom:password_changed_dt': string;
  'custom:sign-verify-enabled': string;
}

export const verifyMFAdevice = async (
  secretCode: string | null,
  username: string | null,
  accessToken: string | null,
  refreshToken: string | null,
  expiresIn: number | null,
  date: string | null,
  deviceKey: string | null,
  idToken: string | null,
  isFirstLogin: boolean,
  isMfaActive: boolean,
  otp2: string
) => {
  const response: any = await universalPostRequest(APIEndpoint.verifyMFA, {
    access_token: accessToken,
    refresh_token: refreshToken,
    id_token: idToken,
    expires_in: expiresIn,
    date: date,
    device_key: deviceKey,
    secret_code: secretCode,
    is_first_login: isFirstLogin,
    is_mfa_active: isMfaActive,
    otp1: '123456',
    otp2: otp2,
    username: username,
  });
  if ('error' in response) {
    popupNotification(response.error, false);
  } else {
    return true;
  }

  // Check if the response is an error
};

export const validateMFA = async (
  username: string | null,
  isFirstLogin: boolean,
  isMfaActive: boolean,
  otp: string,
  sessionKey: string | null
) => {
  const response: any = await universalPostRequest(
    'https://zp7dazbgv6.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
    {
      is_first_login: isFirstLogin,
      is_mfa_active: isMfaActive,
      otp: otp,
      username: username,
      session_key: sessionKey,
    }
  );

  if ('error' in response) {
    return true;
  } else {
    return true;
  }

  // Check if the response is an error
};
