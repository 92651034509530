import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';

// Define the props for the CustomDropdown component
interface CustomDropdownProps<T> {
  name: string;
  control: Control<any>;
  options: string[] | T[];
  labelKey?: keyof T;
  valueKey?: keyof T;
  label: string;
  error?: FieldError;
  loading?: boolean;
}

interface Option {
  [key: string]: any;
}

const CustomDropdown = <T extends Option>({
  name,
  control,
  options = [],
  labelKey = 'label' as keyof T,
  valueKey = 'value' as keyof T,
  label,
  error,
  loading = false,
}: CustomDropdownProps<T>) => {
  // Extract error message
  const errorMessage = error ? error.message : '';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          select
          fullWidth
          size="small"
          disabled={loading}
          label={loading ? 'Loading...' : label}
          placeholder={loading ? 'Loading...' : label}
          error={!!error}
          helperText={errorMessage}
          value={field.value ?? ''} // Ensure value is always defined
          InputProps={{
            endAdornment: loading ? (
              <InputAdornment position="end" sx={{ mr: 5 }}>
                <CircularProgress size={20} /> {/* Small spinner */}
              </InputAdornment>
            ) : null,
          }}
        >
          {/* Check if options are empty*/}
          {options.length === 0 ? (
            <MenuItem disabled>No options available</MenuItem>
          ) : (
            options.map((option, index) =>
              typeof option === 'string' ? (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ) : (
                <MenuItem key={index} value={option[valueKey]}>
                  {option[labelKey]}
                </MenuItem>
              )
            )
          )}
        </TextField>
      )}
    />
  );
};

export default CustomDropdown;
