import { Box, Button, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';

import BackIcon from '../assets/images/backIcon.svg';
import Logo404 from '../assets/images/NotFound.svg';
import useNavigateWithUUID from '../hooks/useNavigateWithUUID';
const PageNotFound = () => {
  const navigate = useNavigate();
  const { navigateWithUuid } = useNavigateWithUUID();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const redirect = () => {
    isLoggedIn ? navigateWithUuid('dashboard') : navigate('/login');
  };
  return (
    <Container sx={{ textAlign: 'center' }}>
      <Typography variant="h2" fontWeight="bold" component="h1">
        Oops !
      </Typography>
      <Typography mt={2} color="textDisabled" variant="body1">
        You are lost
      </Typography>
      <Box mt={5}>
        <img src={Logo404} width="40%" />
      </Box>
      <Box mt={5} display="flex" justifyContent="center" alignItems="center">
        <Box
          component={Button}
          onClick={() => redirect()}
          borderBottom={(theme) => `1px solid ${theme.palette.primary.light}`}
        >
          <img src={BackIcon} />
          <Typography
            ml={2}
            variant="subtitle2"
            color="primary"
            fontWeight="bold"
          >
            Go home
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default PageNotFound;
