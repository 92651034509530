import { universalPostRequest } from 'common/requestHandler';
import * as yup from 'yup';

export const passwordSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),

  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .test('password-complexity', function (value) {
      const errors = [];

      if (!/(?=.*[A-Z].*[A-Z])/.test(value || '')) {
        errors.push('Password must contain at least 2 uppercase letters');
      }

      if (!/(?=.*[a-z].*[a-z])/.test(value || '')) {
        errors.push('Password must contain at least 2 lowercase letters');
      }

      if (!/(?=.*\d.*\d)/.test(value || '')) {
        errors.push('Password must contain at least 2 digits');
      }

      if (
        !/(?=.*[@$!%*?&#^(){}[\]<>].*[@$!%*?&#^(){}[\]<>])/.test(value || '')
      ) {
        errors.push('Password must contain at least 2 special characters');
      }

      if (errors.length) {
        return this.createError({
          message: errors.join(', '),
        });
      }

      return true;
    })
    .notOneOf(
      [yup.ref('currentPassword')],
      'New password must be different from the current password'
    )
    .required('New password is required'),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
});

export const forgotPasswordSchema = yup.object().shape({
  username: yup
    .string()
    .matches(
      /^[A-Za-z0-9'-.@]+$/,
      'Username or email can only contain alphabets and apostrophes'
    )
    .required('Username is required'),
});

export const changePassword = async (
  username: string | null,
  previousPassword: string,
  proposedPassword: string
) => {
  const response: any = await universalPostRequest(
    'https://uo1ab2vg9a.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
    {
      username,
      previousPassword,
      proposedPassword,
    }
  );
  if ('error' in response) {
    return true;
  } else {
    return true;
  }

  // Check if the response is an error
};
export const setForgotPasswordSchema = yup.object().shape({
  username: yup
    .string()

    .matches(
      /^[A-Za-z0-9'.-]+$/,
      'Username can only contain alphabets and apostrophes and -'
    )
    .required('Username is required'),
  password: yup

    .string()
    .required()
    .min(8, 'Password must be at least 8 characters long')
    .required('Password is required')
    .test('password-complexity', function (value) {
      const errors = [];

      if (!/(?=.*[A-Z].*[A-Z])/.test(value || '')) {
        errors.push('Password must contain at least 2 uppercase letters');
      }

      if (!/(?=.*[a-z].*[a-z])/.test(value || '')) {
        errors.push('Password must contain at least 2 lowercase letters');
      }

      if (!/(?=.*\d.*\d)/.test(value || '')) {
        errors.push('Password must contain at least 2 digits');
      }

      if (
        !/(?=.*[@$!%*?&#^(){}[\]<>].*[@$!%*?&#^(){}[\]<>])/.test(value || '')
      ) {
        errors.push('Password must contain at least 2 special characters');
      }

      if (errors.length) {
        return this.createError({
          message: errors.join(', '),
        });
      }

      return true;
    }),
  otp: yup.string().required('OTP is required'),
});
export const forgotUsernameSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});
