import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { APIEndpoint } from 'common/enum';
import { mappingsDataInterface } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import CustomModal from 'components/CustomModal';
import HeaderComp from 'components/elements/HeadingComponent';
import SelectableList from 'components/ListItemsWithSearch';
import useSelectableItems from 'hooks/useSelectableList';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { getMappingsData } from './utils';

const DeleteMapping = () => {
  const [mappingsData, setMappingsData] = useState(
    [] as mappingsDataInterface[]
  );
  const [employees, setEmployees] = useState([] as string[]);
  const [customers, setCustomers] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [deletingMapping, setDeletingMapping] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState(
    {} as mappingsDataInterface
  );
  const { companyId } = useSelector((state: RootState) => state.auth);
  const { selectedItems, toggleItem, resetSelection } = useSelectableItems();
  useEffect(() => {
    getMappingsData(setLoading, setMappingsData, companyId);
  }, []);
  useEffect(() => {
    const filtered = mappingsData.filter(
      (mapping) => mapping.companyStaffCustomerGroupName === selectedItems[0]
    );
    if (filtered.length) {
      setSelectedMapping(filtered[0]);
      setEmployees(filtered[0].employeeId);
      setCustomers(filtered[0].customerId);
    } else {
      setSelectedMapping({} as mappingsDataInterface);
    }
  }, [selectedItems]);
  const deleteMapping = async () => {
    setDeletingMapping(true);
    const payload = {
      companyStaffCustomerGroupName: selectedItems[0],
      companyId: selectedMapping.companyId,
      actionName: 'DeleteStaffCustomerGroup',
    };
    const response = await universalPostRequest(
      APIEndpoint.deleteGroup,
      payload
    );
    resetSelection();

    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
      getMappingsData(setLoading, setMappingsData, companyId);
    }
    setEmployees([]);
    setCustomers([]);
    setDeletingMapping(false);
    setShowModal(false);
  };
  return (
    <>
      <HeaderComp>Delete mapping</HeaderComp>
      <Grid container spacing={8}>
        <Grid item lg={4} md={6} sm={12}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Mappings
          </Typography>
          <SelectableList
            items={mappingsData}
            selectedItems={selectedItems}
            toggleItem={toggleItem}
            searchVisible={true}
            showSelectHighlight
            showCheckBoxIcon={false}
            allowSingleSelection
            loading={loading}
            idKey="companyStaffCustomerGroupName" // Nested key
            labelKey="companyStaffCustomerGroupName" // Nested label
            height={150}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Staff group members
          </Typography>
          <Box
            height="100%"
            sx={{
              borderRadius: 4,
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <List>
              {employees.map((employee, index) => (
                <>
                  <ListItem key={employee}>
                    <Typography variant="body2">{employee}</Typography>
                  </ListItem>
                  {index !== employees.length - 1 && <Divider />}
                </>
              ))}
              {!employees.length && (
                <Typography variant="body1" textAlign="center" color={red[400]}>
                  No members
                </Typography>
              )}
            </List>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={12}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Customer group members
          </Typography>
          <Box
            height="100%"
            sx={{
              borderRadius: 4,
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <List>
              {customers.map((customer, index) => (
                <>
                  <ListItem key={customer}>
                    <Typography variant="body2">{customer}</Typography>
                  </ListItem>
                  {index !== customers.length - 1 && <Divider />}
                </>
              ))}
              {!customers.length && (
                <Typography variant="body1" textAlign="center" color={red[400]}>
                  No members
                </Typography>
              )}
            </List>
          </Box>
        </Grid>
        <Grid xs={12}>
          <Box display="flex" justifyContent="flex-end" mt={8}>
            <Button
              variant="contained"
              color="error"
              onClick={() => setShowModal(true)}
              disabled={!selectedMapping?.companyStaffCustomerGroupName}
            >
              Delete mapping
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        type="error"
        open={showModal}
        autoClose={false}
        onClose={() => setShowModal(false)}
        primaryButtonAction={deleteMapping}
        primaryButtonLabel="Delete group"
        secondaryButtonAction={() => setShowModal(false)}
        secondaryButtonLabel="Cancel"
        primaryButtonLoading={deletingMapping}
        title={`Delete mapping: ${selectedItems[0]}`}
      >
        <Typography variant="body1">
          Mapping {selectedItems[0]} will be
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: red[400] }}
            component="span"
          >
            {' '}
            Deleted{' '}
          </Typography>
          along with all its members.
        </Typography>
        <Typography variant="body1">Do you want to continue ?</Typography>
      </CustomModal>
    </>
  );
};

export default DeleteMapping;
