import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Box, IconButton } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { addressFormInterface } from 'common/interface';
import { universalPutRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import colors from 'theme/colors';

import GradientButton from '../elements/GradientButton';
import InputElement from '../elements/InputElement';
import { validatePersonalDetails } from './validation';

const AddressUpdateComp: React.FC<{
  onAddressChange?: (data: addressFormInterface) => void;
  handleSubmitForm?: (data: addressFormInterface) => void; // Custom submit handler
  addressDefaultValues?: addressFormInterface;
}> = ({ onAddressChange, handleSubmitForm, addressDefaultValues }) => {
  const { username } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const {
    control,
    watch,
    handleSubmit,
    reset, // To reset form when default values change
    formState: { errors },
  } = useForm<addressFormInterface>({
    resolver: yupResolver(validatePersonalDetails),
    mode: 'onChange',
    defaultValues: addressDefaultValues || {}, // Default values passed via props
  });

  // Watch for changes in form fields
  const addressValues = watch();

  useEffect(() => {
    if (addressDefaultValues) {
      reset(addressDefaultValues); // Use reset to update form when defaultValues change
    }
  }, [addressDefaultValues, reset]);

  // Default submit handler if no custom handler is provided
  const defaultSubmitHandler = async (data: addressFormInterface) => {
    setLoading(true);
    const addressObject = {
      address1: data.address1,
      address2: data.address2,
      address3: data.address3,
      city: data.city,
      state: data.state,
      postCode: data.postCode,
    };

    const response = await universalPutRequest(APIEndpoint.updateUserDetails, {
      username: username,
      address: JSON.stringify(addressObject),
      actionName: 'user',
    });

    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
    }

    setLoading(false);
  };

  // Form submit handler, uses the custom handler if passed, otherwise falls back to default
  const submitHandler = (data: addressFormInterface) => {
    if (handleSubmitForm) {
      // If custom handler is provided, call it with the form data
      handleSubmitForm(data);
    } else {
      // Otherwise, use the default submit logic
      defaultSubmitHandler(data);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center" mt={5}>
        <Box
          minWidth="100%"
          sx={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)' }}
          p={5}
          pt={2}
          borderRadius={8}
        >
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <IconButton onClick={() => setIsEditable((prev) => !prev)}>
              <ModeEditIcon sx={{ color: colors.editIcon }} />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit(submitHandler)}>
            <InputElement
              name="address1"
              control={control}
              label="Address 1"
              disabled={isEditable}
              type="text"
              rules={{ required: true }}
            />
            <InputElement
              name="address2"
              control={control}
              label="Address 2"
              type="text"
              disabled={isEditable}
              rules={{ required: true }}
            />
            <InputElement
              name="address3"
              control={control}
              label="Address 3"
              disabled={isEditable}
              type="text"
              rules={{ required: true }}
            />
            <InputElement
              name="city"
              control={control}
              label="City"
              disabled={isEditable}
              type="text"
              rules={{ required: true }}
            />
            <InputElement
              name="state"
              control={control}
              label="State"
              disabled={isEditable}
              type="text"
              rules={{ required: true }}
            />
            <InputElement
              name="postCode"
              control={control}
              label="Postal code"
              disabled={isEditable}
              type="text"
              rules={{ required: true }}
            />
            <Box textAlign="right" mt={4}>
              <GradientButton
                type="submit"
                disabled={isEditable}
                rounded
                loading={loading}
              >
                Update
              </GradientButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AddressUpdateComp;
