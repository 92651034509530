import { useEffect, useState } from 'react';

import QRCode from 'qrcode';

const QRBlock = ({ source }: { source: any }) => {
  return (
    <>
      {source ? (
        <img width="250px" src={source} alt="QR code" />
      ) : (
        <div>
          <p className="text-danger">
            Failed generating QR code
            <br />
            <span className="clickable-text text-primary" onClick={() => {}}>
              Try again
            </span>
          </p>
        </div>
      )}
    </>
  );
};

const QRcode = ({
  secret_code,
  username,
  company,
}: {
  secret_code: string;
  username: string | null;
  company: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [src, setSrc] = useState('');
  const encodedUsername = username ? encodeURIComponent(username) : '';
  const encodedSecret = encodeURIComponent(secret_code);
  const encodedIssuer = encodeURIComponent(company);
  useEffect(() => {
    QRCode.toDataURL(
      `otpauth://totp/${encodedUsername}?secret=${encodedSecret}&issuer=${encodedIssuer}`
    ).then(setSrc);
    setLoading(false);
  }, []);

  return (
    <div className="text-center mx-auto">
      {loading ? <div> Loading... </div> : <QRBlock source={src} />}
    </div>
  );
};

export default QRcode;
