import React, { useState } from 'react';

import { Box, Container, TextField, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';
import { userTypes } from 'config/config';
import { jwtDecode } from 'jwt-decode';
import { popupNotification } from 'notifications/ToastNotification';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';

import { login } from '../../redux/slices/authSlice';
import { setIdTokenData } from '../../redux/slices/idTokenSlice';
import GradientButton from '../elements/GradientButton';
import { JWTDecodedIDToken, validateMFAInterface } from './utility';

const VerifyMFA = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const { username, isFirstLogin, isMfaActive, sessionKey, role } = useSelector(
    (state: RootState) => state.auth
  );
  const handleMFAVerify = async () => {
    setLoading(true);
    const response = await universalPostRequest<validateMFAInterface>(
      APIEndpoint.validateMFAOTP,
      {
        is_first_login: isFirstLogin,
        is_mfa_active: isMfaActive,
        otp: input,
        username: username,
        session_key: sessionKey,
      }
    );
    setLoading(false);

    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      const decodedData: JWTDecodedIDToken = jwtDecode(response.data.id_token);
      const tokenData = {
        authTime: decodedData.auth_time,
        phoneNumber: decodedData.phone_number,
        email: decodedData.email,
        username: decodedData.username,
        employeeId: decodedData['custom:employee-id'],
        companyId: decodedData['custom:company-id'],
        corporateId: decodedData['custom:corporate-id'],
        customerId: decodedData['custom:customer-id'],
        emailVerified: decodedData.email_verified,
      };
      const data = {
        accessToken: response.data.access_token,
        expiresIn: response.data.expires_in,
        idToken: response.data.id_token,
        refreshToken: response.data.refresh_token,
        role: decodedData['custom:user-type'],
        employeeId: decodedData['custom:employee-id'],
        customerId: decodedData['custom:customer-id'],
        corporateId: decodedData['custom:corporate-id'],
        companyId: decodedData['custom:company-id'],
        firstName: decodedData['given_name'],
        lastName: decodedData['family_name'],
        isLoggedIn: true,
        lastPasswordChangeDate: decodedData['custom:password_changed_dt'],
        isSignVerifyEnabled:
          decodedData['custom:sign-verify-enabled'] === 'True' ? true : false,
      };
      dispatch(login(data));
      dispatch(setIdTokenData(tokenData));
      if (decodedData['custom:user-type'] === userTypes.superAdmin) {
        navigate('/dashboard');
      } else {
        navigate(`/${tokenData.companyId}/dashboard`);
      }
    }
  };
  return (
    <React.Fragment>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <Box
          minWidth="50%"
          sx={{
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
          }}
        >
          <Typography variant="h5" component="h1">
            Enter OTP from authenticator app
          </Typography>
          <form style={{ width: '100%' }}>
            <Box
              display="flex"
              justifyContent="flex-end"
              gap={4}
              mt={5}
              width="100%"
            >
              <TextField
                label={`user: ${username}`}
                name="input"
                autoFocus
                value={input}
                placeholder="Enter OTP from authenticator app"
                focused
                fullWidth
                size="small"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setInput(e.target.value)}
              />
              <GradientButton
                rounded
                type="submit"
                disabled={input.length !== 6}
                loading={loading}
                onClick={() => {
                  handleMFAVerify();
                }}
              >
                Verify
              </GradientButton>
            </Box>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default VerifyMFA;
