import { userTypes } from 'config/config';
import PageNotFound from 'pages/404';
import { Route, Routes } from 'react-router-dom';
import ContractManagement from 'screens/contractManagement/ContractManagement';
import PreferenceCorporate from 'screens/corporate/PreferenceCorporate';
import DashboardPage from 'screens/dashboard/Dashboard';
import ChangePassword from 'screens/password/ChangePassword';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const CorporateRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.corporate]}>
    <Layout>
      <Routes>
        <Route path="dashboard/*" element={<DashboardPage />} />
        <Route path="preference" element={<PreferenceCorporate />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="/contract-management" element={<ContractManagement />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default CorporateRoutes;
