import { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { addressFormInterface } from 'common/interface';
import { universalGetRequest } from 'common/requestHandler';
import GradientButton from 'components/elements/GradientButton';
import useNavigateWithUUID from 'hooks/useNavigateWithUUID';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import AddressComp from '../../components/address/AddressUpdateComp';
import HeaderComp from '../../components/elements/HeadingComponent';
import Loader from '../../components/elements/Loader';

const PreferenceIndividual = () => {
  const { companyId, username } = useSelector((state: RootState) => state.auth);
  const [userDefaultAddress, setUserDefaultAddress] = useState(
    {} as addressFormInterface
  );
  const [loading, setLoading] = useState(false);
  const { navigateWithUuid } = useNavigateWithUUID();
  const [userEmail, setUserEmail] = useState('');
  const getCompanyDetails = async () => {
    setLoading(true);
    const response: any = await universalGetRequest(
      APIEndpoint.getUserDetails,
      {
        actionName: 'user',
        username: username,
        companyId: companyId,
      }
    );
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      if (response.data.address) {
        const address = response?.data?.address;
        setUserEmail(response?.data.email);
        setUserDefaultAddress(JSON.parse(address));
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getCompanyDetails();
  }, []);
  return (
    <>
      <HeaderComp>Personal details</HeaderComp>
      {loading ? (
        <Loader />
      ) : (
        <Grid container>
          <Grid item md={12}>
            <Box
              sx={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)' }}
              p={4}
              borderRadius={8}
              display="flex"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" gap={1}>
                  <Typography variant="body2" fontWeight="bold">
                    Username:
                  </Typography>
                  <Typography variant="body2">{username}</Typography>
                </Box>
                <Box display="flex" gap={2}>
                  <Typography variant="body2" fontWeight="bold">
                    Email:
                  </Typography>
                  <Typography variant="body2">{userEmail}</Typography>
                </Box>
              </Box>
              <Box>
                <GradientButton
                  onClick={() => navigateWithUuid('change-password')}
                >
                  Change password
                </GradientButton>
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <AddressComp addressDefaultValues={userDefaultAddress} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PreferenceIndividual;
