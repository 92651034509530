import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';

export const createFolder = async (path: string, newFolderName: string) => {
  const response: any = await universalPostRequest(APIEndpoint.createFolder, {
    path: path,
    'new-folder-name': newFolderName,
  });
  return response;

  // Check if the response is an error
};
