import { useState } from 'react';

import GetForgotPassword from './GetForgotPasswordOTP';
import SetForgotPassword from './SetForgotPassword';

const ForgotPassword = () => {
  const [isOTPReceived, setIsOTPReceived] = useState(false);
  return !isOTPReceived ? (
    <GetForgotPassword setIsOTPReceived={setIsOTPReceived} />
  ) : (
    <SetForgotPassword />
  );
};

export default ForgotPassword;
