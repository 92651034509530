import React from 'react';

import { Button, ButtonProps } from '@mui/material';
import { PulseLoader } from 'react-spinners';

// Define props for the custom button component
interface GradientButtonProps extends ButtonProps {
  rounded?: boolean; // Optional prop to make the button rounded
  loading?: boolean; // Optional prop to indicate loading state
  loadingText?: string; // Optional text to show when loading
}

const GradientButton: React.FC<GradientButtonProps> = ({
  rounded = false, // Default to false if not passed
  loading = false, // Default to false if not passed
  loadingText = '', // Default to empty string if not passed
  children,
  ...props
}) => {
  return (
    <Button
      {...props}
      disabled={loading || props.disabled} // Disable button when loading or if disabled is passed
      sx={{
        background:
          loading || props.disabled
            ? 'linear-gradient(45deg, #b2b2b2, #c2c2c2)' // Lightened grey gradient when disabled/loading
            : 'linear-gradient(45deg, #42d392, #647eff)', // Normal gradient
        color: 'white',
        fontWeight: 'bold',
        padding: '5px 20px',
        boxShadow:
          loading || props.disabled
            ? '0px 10px 20px rgba(0, 0, 0, 0.1)' // Lighter shadow when disabled/loading
            : '0px 10px 20px rgba(0, 0, 0, 0.2)', // Normal shadow
        borderRadius: rounded ? '50px' : '4px', // Rounded corners based on prop
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        opacity: loading || props.disabled ? 0.7 : 1, // Reduce opacity when disabled/loading
        cursor: loading || props.disabled ? 'not-allowed' : 'pointer', // Show 'not-allowed' cursor when disabled/loading
        position: 'relative',
        '&:hover': {
          boxShadow:
            loading || props.disabled
              ? 'none' // No hover effect when disabled/loading
              : '0px 6px 12px rgba(0, 0, 0, 0.2)', // Elevated shadow on hover
          transform: loading || props.disabled ? 'none' : 'translateY(-2px)', // No transformation when disabled/loading
          background:
            loading || props.disabled
              ? 'linear-gradient(45deg, #b2b2b2, #c2c2c2)' // Keep the grey gradient when hovering in disabled/loading state
              : 'linear-gradient(45deg, #42d392, #647eff)', // Normal gradient on hover
        },
      }}
    >
      {loading ? (
        <>
          <PulseLoader size={8} color="white" />
          {loadingText && (
            <span style={{ marginLeft: '10px' }}>{loadingText}</span>
          )}
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default GradientButton;
