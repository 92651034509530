import React, { useEffect, useState } from 'react';

import { Box, Container, Link, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';

import { logout } from '../../redux/slices/authSlice';
import CustomModal from '../CustomModal';
import GradientButton from '../elements/GradientButton';
import QRcode from './QRCode';
import { verifyMFAdevice } from './utility';

const MFAConfig = () => {
  const {
    secretCode,
    username,
    accessToken,
    refreshToken,
    expiresIn,
    date,
    deviceKey,
    idToken,
    isFirstLogin,
    isMfaActive,
  } = useSelector((state: RootState) => state.auth);
  const [otp2, setotp2] = useState('');
  const [countdown, setCountdown] = useState(120);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyName = process.env.REACT_APP_URL || 'DocuHub';
  const handleMFA = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const isSuccess = await verifyMFAdevice(
      secretCode,
      username,
      accessToken,
      refreshToken,
      expiresIn,
      date,
      deviceKey,
      idToken,
      isFirstLogin,
      isMfaActive,
      otp2
    );
    setLoading(false);
    if (isSuccess) {
      navigate('/reset-password');
    }
  };
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setShowModal(true);
    }
  }, [countdown]);
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };
  useEffect(() => {
    if (!accessToken || !isFirstLogin) {
      navigate('/login');
    }
  }, []);

  return (
    <React.Fragment>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
          }}
        >
          <Typography variant="h4" component="h1">
            Register your device
          </Typography>
          <ul>
            <li>
              Get the authenticator app from the{' '}
              <Link
                href="https://play.google.com/store/search?q=Authenticator&c=apps"
                rel="noreferrer"
                target="_blank"
                sx={{ textDecoration: 'none' }}
              >
                Play Store
              </Link>{' '}
              or{' '}
              <Link
                href="https://apps.apple.com/search?term=Authenticator"
                rel="noreferrer"
                target="_blank"
                sx={{ textDecoration: 'none' }}
              >
                App Store
              </Link>
            </li>
            <li>
              In the app select{' '}
              <span style={{ fontWeight: 600 }}>Set up account</span>
            </li>
            <li>
              Choose <span style={{ fontWeight: 600 }}>Scan barcode</span>
            </li>
          </ul>
          <QRcode
            secret_code={secretCode}
            username={username}
            company={companyName}
          />
          <Box width="100%">
            <form onSubmit={(e) => handleMFA(e)}>
              <Box className="mfa-input" mt={3}>
                <TextField
                  size="small"
                  fullWidth
                  autoFocus
                  className="mt-2"
                  label="Enter code from authenticator"
                  value={otp2}
                  onChange={(e) => setotp2(e.target.value)}
                  disabled={loading}
                />
              </Box>
              <Box className="mfa-timer" mt={2}>
                <Typography
                  variant="h6"
                  align="center"
                  color={countdown > 10 ? 'primary' : 'error'}
                >
                  {formatTime(countdown)}
                </Typography>
              </Box>
              <Box className="mfa-footer" textAlign="right" mt={1}>
                <GradientButton
                  fullWidth
                  variant="contained"
                  type="submit"
                  loading={loading}
                  size="small"
                  disabled={otp2.length !== 6 || loading}
                >
                  Verify
                </GradientButton>
              </Box>
            </form>
          </Box>
        </Box>
        <CustomModal
          open={showModal}
          title="Session timed out"
          onClose={() => setShowModal(false)}
          primaryButtonAction={() => {
            dispatch(logout());
            navigate('/login');
          }}
          primaryButtonLabel="Login"
          type="error"
        >
          <Typography variant="body1">
            Your session has timed out, please re-login and try again.
          </Typography>
        </CustomModal>
      </Container>
    </React.Fragment>
  );
};

export default MFAConfig;
