import React from 'react';

import { APIEndpoint } from 'common/enum';
import {
  customerGroupInterface,
  mappingsDataInterface,
  staffGroupInterface,
} from 'common/interface';
import { universalGetRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';

export interface getUserInterface {
  id: string;
  name: string;
}
export const getStaffGroups = async (
  companyId: string | null,
  setGroupList: (value: React.SetStateAction<staffGroupInterface[]>) => void,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!companyId) return;
  setLoading(true);
  const payload = {
    actionName: 'GetStaffGroups',
    companyId,
  };
  const response: any = await universalGetRequest(
    APIEndpoint.getGroups,
    payload
  );
  if ('error' in response) {
    popupNotification(response.error, false);
  } else {
    const parsedData: staffGroupInterface[] = response.data.map((data: any) =>
      data.length ? data[0].company_data : []
    );
    setGroupList(parsedData);
  }
  setLoading(false);
};
export const getCustomerGroups = async (
  companyId: string | null,
  setGroupList: (value: React.SetStateAction<customerGroupInterface[]>) => void,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!companyId) return;
  setLoading(true);
  const payload = {
    actionName: 'GetCustomerGroups',
    companyId,
  };
  const response: any = await universalGetRequest(
    APIEndpoint.getGroups,
    payload
  );
  if ('error' in response) {
    popupNotification(response.error, false);
  } else {
    const parsedData: customerGroupInterface[] = response.data.map(
      (data: any) => (data.length ? data[0].company_data : [])
    );
    setGroupList(parsedData);
  }
  setLoading(false);
};
export const getMappingsData = async (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setMappingsData: React.Dispatch<
    React.SetStateAction<mappingsDataInterface[]>
  >,
  companyId: string | null
) => {
  if (!companyId) return;
  setLoading(true);
  const response: any = await universalGetRequest(APIEndpoint.getGroups, {
    actionName: 'GetStaffCustomerGroups',
    companyId,
  });
  const parsedData: any[] = response.data.map((data: any) =>
    data.length ? data[0].cc : []
  );
  setMappingsData(parsedData);
  setLoading(false);
};
