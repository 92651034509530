import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { RootState } from '../redux/store'; // Adjust the path according to your store

interface ProtectedRouteProps {
  children: JSX.Element;
  allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoles,
}) => {
  const { accessToken, role } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  // Handle the case where `role` might be null
  if (!role || !allowedRoles.includes(role)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;
