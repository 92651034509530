import { useState } from 'react';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { Box, Button, Grid, Typography } from '@mui/material';
import GradientCardComponent from 'components/elements/GradientCard';
import HeaderComp from 'components/elements/HeadingComponent';

import CreateMapping from './CreateMappings';
import DeleteMapping from './DeleteMapping';

const StaffGroups = () => {
  const options = ['Create staff group', 'Delete staff group'];
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  return (
    <>
      {selectedOption === 'Create mapping' ? (
        <>
          <Box
            component={Button}
            display="flex"
            alignItems="center"
            gap={1}
            mb={2}
            onClick={() => setSelectedOption(null)}
          >
            <FirstPageIcon color="primary" />
            <Typography variant="subtitle1" color="primary">
              Go back
            </Typography>
          </Box>
          <CreateMapping />
        </>
      ) : selectedOption === 'Delete mapping' ? (
        <>
          <Box
            display="flex"
            component={Button}
            alignItems="center"
            gap={1}
            mb={2}
            onClick={() => setSelectedOption(null)}
          >
            <FirstPageIcon color="primary" />
            <Typography variant="subtitle1" color="primary">
              Go back
            </Typography>
          </Box>
          <DeleteMapping />
        </>
      ) : (
        <>
          <HeaderComp>Mappings</HeaderComp>
          <Grid container gap={1}>
            <Grid item lg={4}>
              <GradientCardComponent>
                <Box
                  pt={1}
                  pb={1}
                  display="flex"
                  gap={2}
                  onClick={() => {
                    setSelectedOption('Create mapping');
                  }}
                >
                  <GroupAddIcon />
                  Create mapping
                </Box>
              </GradientCardComponent>
            </Grid>
            <Grid item lg={4}>
              <GradientCardComponent type="error">
                <Box
                  pt={1}
                  pb={1}
                  display="flex"
                  gap={2}
                  onClick={() => {
                    setSelectedOption('Delete mapping');
                  }}
                >
                  <GroupRemoveIcon />
                  <Typography variant="body1">Delete mapping</Typography>
                </Box>
              </GradientCardComponent>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default StaffGroups;
