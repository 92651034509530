import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userTypesInterface } from 'common/interface';

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  idToken: string | null;
  expiresIn: number | null;
  date: string | null;
  deviceKey: string | null;
  secretCode: string;
  username: string | null;
  isFirstLogin: boolean;
  isMfaActive: boolean;
  role: userTypesInterface | null;
  sessionKey: string | null;
  employeeId: string | null;
  customerId: string | null;
  corporateId: string | null;
  companyId: string | null;
  firstName: string | null;
  lastName: string | null;
  isLoggedIn: boolean;
  lastPasswordChangeDate: string | null;
  forcePasswordChangeModalAccepted: boolean;
  isSignVerifyEnabled: boolean;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  idToken: null,
  expiresIn: null,
  date: null,
  deviceKey: null,
  secretCode: '',
  username: null,
  isFirstLogin: false,
  isMfaActive: false,
  role: null,
  sessionKey: null,
  employeeId: null,
  customerId: null,
  corporateId: null,
  companyId: null,
  firstName: null,
  lastName: null,
  isLoggedIn: false,
  lastPasswordChangeDate: null,
  forcePasswordChangeModalAccepted: false,
  isSignVerifyEnabled: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<Partial<AuthState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    logout() {
      return initialState;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
