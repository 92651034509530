import { useState } from 'react';

import axios from 'axios';
import { APIEndpoint } from 'common/enum'; // Adjust import path accordingly
import {
  allowedFileTypes,
  largeFileTypes,
  logoFileName,
  MAX_FILE_SIZE_MB,
  MAX_FILE_SIZE_MB_LARGE_FILES,
} from 'config/config';
import { popupNotification } from 'notifications/ToastNotification'; // Assuming this is the correct notification method
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { fileNameValidation } from 'validation/validationScheme';

const defaultErrorMessage = 'File upload failed. Please try again.';

export const useFileUpload = () => {
  const [progress, setProgress] = useState<{ [key: string]: number }>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
  const MAX_FILE_SIZE_BYTES_FOR_LARGE_FILES =
    MAX_FILE_SIZE_MB_LARGE_FILES * 1024 * 1024;
  const uploadFiles = async (
    uuid: string | undefined | null,
    folderId: string | null,
    firstName: string | null,
    lastName: string | null,
    newFolderPath: string,
    setModalOpen: (isOpen: boolean) => void,
    home: string | null = 'Home'
  ) => {
    setIsUploading(true);

    const uploadPromises = files.map(async (file) => {
      // Check if file size exceeds the limit
      const msxFileSize = largeFileTypes.includes(file.type)
        ? MAX_FILE_SIZE_BYTES_FOR_LARGE_FILES
        : MAX_FILE_SIZE_BYTES;
      if (file.size > msxFileSize) {
        popupNotification(
          `File ${file.name} is too large. Maximum allowed size is ${
            largeFileTypes.includes(file.type)
              ? MAX_FILE_SIZE_BYTES_FOR_LARGE_FILES
              : MAX_FILE_SIZE_BYTES
          }MB.`,
          false
        );
        return; // Skip uploading this file
      }
      if (!fileNameValidation.test(file.name)) {
        popupNotification(`Invalid file name ${file.name}`, false);
        return;
      }
      if (
        !allowedFileTypes.includes(file.type) &&
        !largeFileTypes.includes(file.type)
      ) {
        popupNotification(`File ${file.name} is not a valid file type.`, false);
        return; // Skip uploading this file
      }

      try {
        const { data } = await axios.post(
          APIEndpoint.uploadFile,
          {
            fileName: `${uuid}/${folderId ? folderId + '/' : ''}${
              home ? home + '/' : ''
            }${newFolderPath.length > 0 ? newFolderPath + '/' : ''}${
              home ? file.name : logoFileName
            }`,
            uploadedBy: `${firstName} ${lastName}`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken,
            },
          }
        );

        const uploadUrl = data.upload_url.url;

        const formData = new FormData();
        if (data.upload_url.fields) {
          Object.entries(data.upload_url.fields).forEach(([k, v]: any) => {
            formData.append(k, v);
          });
        } else {
          console.error('No fields found in the response');
        }

        formData.append('file', file);

        await axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total ?? progressEvent.loaded;
            const progressPercentage = Math.round(
              (progressEvent.loaded / total) * 100
            );
            setProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progressPercentage,
            }));
          },
        });
        popupNotification('Files uploaded successfully', true);
      } catch (error) {
        popupNotification(defaultErrorMessage, false);
      }
    });

    await Promise.all(uploadPromises).then(() => {
      // popupNotification('Files uploaded successfully', true);
      setModalOpen(false);
      setFiles([]); // Clear files after upload
      setProgress({}); // Reset progress after upload
      setIsUploading(false);
    });
  };

  const setSelectedFiles = (selectedFiles: File[]) => {
    setFiles(selectedFiles);
    setProgress({}); // Reset progress when new files are selected
  };

  return { uploadFiles, progress, isUploading, setSelectedFiles, files };
};
