import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Grid, List, ListItem, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { APIEndpoint } from 'common/enum';
import { customerGroupInterface, staffGroupInterface } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { validateGroupName } from 'validation/validationScheme';

import CustomModal from '../../components/CustomModal';
import GradientButton from '../../components/elements/GradientButton';
import HeaderComp from '../../components/elements/HeadingComponent';
import InputElement from '../../components/elements/InputElement';
import SelectableList from '../../components/ListItemsWithSearch';
import useSelectableItems from '../../hooks/useSelectableList';
import { getCustomerGroups, getStaffGroups } from './utils';

interface groupNameInterface {
  groupName: string;
}
const CreateMapping = () => {
  const [loadingStaffGroups, setLoadingStaffGroups] = useState(false);
  const [loadingCustomerGroups, setLoadingCustomerGroups] = useState(false);
  const [creatingMapping, setCreatingMapping] = useState(false);
  const [customerGroups, setCustomerGroups] = useState(
    [] as customerGroupInterface[]
  );
  const [staffGroups, setStaffGroups] = useState([] as staffGroupInterface[]);
  const [staffGroupMembers, setStaffGroupMembers] = useState([] as string[]);
  const [customerGroupMembers, setCustomerGroupMembers] = useState(
    [] as string[]
  );
  const [selectedStaffGroup, setSelectedStaffGroup] = useState(
    {} as staffGroupInterface
  );
  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState(
    {} as customerGroupInterface
  );
  const [showModal, setShowModal] = useState(false);
  const { companyId } = useSelector((state: RootState) => state.auth);

  const {
    selectedItems: selectedLeftItems,
    toggleItem: toggleLeftItem,
    resetSelection: resetSelectedLeftItems,
  } = useSelectableItems();
  const {
    selectedItems: selectedRightItems,
    toggleItem: toggleRightItem,
    resetSelection: resetSelectedRightItems,
  } = useSelectableItems();
  const { control, handleSubmit, reset } = useForm<groupNameInterface>({
    resolver: yupResolver(validateGroupName),
    mode: 'onChange',
    defaultValues: {
      groupName: '',
    } as groupNameInterface,
  });
  useEffect(() => {
    getStaffGroups(companyId, setStaffGroups, setLoadingStaffGroups);
    getCustomerGroups(companyId, setCustomerGroups, setLoadingCustomerGroups);
  }, []);

  useEffect(() => {
    const filteredGroup = staffGroups.filter(
      (group) => group.companyStaffGroupName == selectedLeftItems[0]
    );
    setSelectedStaffGroup(filteredGroup[0]);
    setStaffGroupMembers(filteredGroup.flatMap((group) => group.employeeIds));
  }, [selectedLeftItems]);

  useEffect(() => {
    const filteredGroup = customerGroups.filter(
      (group) => group.companyCustomerGroupName == selectedRightItems[0]
    );
    setSelectedCustomerGroup(filteredGroup[0]);
    setCustomerGroupMembers(
      filteredGroup.flatMap((group) => group.customerIds)
    );
  }, [selectedRightItems]);

  const createMapping = async (data: groupNameInterface) => {
    setCreatingMapping(true);
    const payload = {
      companyId: companyId,
      companyStaffGroupId: selectedStaffGroup.companyStaffGroupId,
      companyStaffGroupName: selectedStaffGroup.companyStaffGroupName,
      companyCustomerGroupName: selectedCustomerGroup.companyCustomerGroupName,
      companyStaffCustomerGroupName: data.groupName,
      actionName: 'CreateStaffCustomerGroup',
    };
    const response = await universalPostRequest(
      APIEndpoint.createMapping,
      payload
    );
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
    }
    setShowModal(false);
    // Reset states
    setSelectedCustomerGroup({} as customerGroupInterface);
    setSelectedStaffGroup({} as staffGroupInterface);
    resetSelectedLeftItems();
    resetSelectedRightItems();
    reset();
    setCreatingMapping(false);
  };
  return (
    <>
      <HeaderComp>Create mapping</HeaderComp>
      <Grid container spacing={8}>
        <Grid item lg={6}>
          <Box display="flex" flexDirection="column" height="100%" gap={2}>
            <Typography variant="body1" color="textDisabled">
              Staff groups
            </Typography>
            <SelectableList
              items={staffGroups}
              selectedItems={selectedLeftItems}
              toggleItem={toggleLeftItem}
              searchVisible={true}
              showSelectHighlight
              showCheckBoxIcon={false}
              allowSingleSelection
              loading={loadingStaffGroups}
              idKey="companyStaffGroupName" // Nested key
              labelKey="companyStaffGroupName" // Nested label
              height={150}
            />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box display="flex" flexDirection="column" height="100%" gap={2}>
            <Typography variant="body1" color="textDisabled">
              Customer groups
            </Typography>
            <SelectableList
              items={customerGroups}
              selectedItems={selectedRightItems}
              toggleItem={toggleRightItem}
              searchVisible={true}
              showSelectHighlight
              showCheckBoxIcon={false}
              allowSingleSelection
              loading={loadingCustomerGroups}
              idKey="companyCustomerGroupName" // Nested key
              labelKey="companyCustomerGroupName" // Nested label
              height={150}
            />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Staff group members
          </Typography>
          <Box
            height="100%"
            sx={{
              borderRadius: 4,
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <List>
              {staffGroupMembers.map((member, index) => (
                <>
                  <ListItem>{member}</ListItem>
                  {index !== staffGroupMembers.length - 1 && <Divider />}
                </>
              ))}
              {!staffGroupMembers.length && (
                <Typography variant="body1" textAlign="center" color={red[400]}>
                  No members
                </Typography>
              )}
            </List>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Customer group members
          </Typography>
          <Box
            height="100%"
            sx={{
              borderRadius: 4,
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <List>
              {customerGroupMembers.map((member, index) => (
                <>
                  <ListItem>{member}</ListItem>
                  {index !== customerGroupMembers.length - 1 && <Divider />}
                </>
              ))}
              {!customerGroupMembers.length && (
                <Typography variant="body1" textAlign="center" color={red[400]}>
                  No members
                </Typography>
              )}
            </List>
          </Box>
        </Grid>
        <Grid item lg={12}>
          <Box display="flex" justifyContent="flex-end">
            <GradientButton
              rounded
              onClick={() => setShowModal(true)}
              sx={{ justifySelf: 'flex-end' }}
              disabled={!selectedLeftItems.length || !selectedRightItems.length}
            >
              Create Mapping
            </GradientButton>
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        primaryButtonAction={handleSubmit(createMapping)}
        primaryButtonLabel="Create mapping"
        autoClose={false}
        open={showModal}
        primaryButtonLoading={creatingMapping}
        type="info"
        onClose={() => {
          setShowModal(false);
          reset();
        }}
        title={`${selectedLeftItems}  >>>>  ${selectedRightItems}`}
      >
        <form onSubmit={handleSubmit(createMapping)}>
          <Typography variant="body2" color="textDisabled">
            {' '}
            Type the mapping name
          </Typography>
          <InputElement
            name="groupName"
            size="small"
            control={control}
            label="Mapping name"
            placeholder="Mapping name"
            autoFocus={true}
            type="text"
            rules={{ required: true }} // Rules can be added here
          />
        </form>
      </CustomModal>
    </>
  );
};

export default CreateMapping;
