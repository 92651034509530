import { Box, Typography } from '@mui/material';

function SampleContract() {
  return (
    <Box padding={3}>
      <Typography variant="h5" gutterBottom>
        Terms and Conditions for E-Signature and Document Management
      </Typography>

      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        Effective Date: [Insert Date]
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography paragraph>
        By using the e-signature and document management services provided by
        DocuHub ("we," "us," "our"), you ("User") agree to be bound by these
        Terms and Conditions ("Terms"). Please read these Terms carefully, as
        they govern your use of our electronic signature and document management
        tools.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Description of Services
      </Typography>
      <Typography paragraph>
        Our e-signature and document management feature allows Users to:
      </Typography>
      <Typography component="ul" paragraph>
        <Typography component="li">
          Electronically sign contracts, agreements, and other documents.
        </Typography>
        <Typography component="li">
          Store, manage, and track document status and signatures.
        </Typography>
        <Typography component="li">
          Access signed documents securely.
        </Typography>
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. User Responsibilities
      </Typography>
      <Typography paragraph>
        <strong>Accurate Information</strong>: Users must provide accurate,
        complete, and current information when using our services.
      </Typography>
      <Typography paragraph>
        <strong>Authentication and Verification</strong>: Users are responsible
        for ensuring the authenticity of their electronic signatures and
        verifying that all parties to a document have the necessary authority to
        sign.
      </Typography>
      <Typography paragraph>
        <strong>Legally Binding Signatures</strong>: Users acknowledge that
        their electronic signatures may be legally binding in accordance with
        applicable laws (such as the ESIGN Act, UETA, or other relevant local
        electronic signature legislation).
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Authorization for Electronic Signatures
      </Typography>
      <Typography paragraph>
        By electronically signing documents using our services, you agree that:
      </Typography>
      <Typography component="ul" paragraph>
        <Typography component="li">
          The electronic signatures applied to any document are legally
          equivalent to traditional handwritten signatures.
        </Typography>
        <Typography component="li">
          Your electronic signature has the same validity, enforceability, and
          admissibility as a physical signature in a court of law.
        </Typography>
        <Typography component="li">
          You authorize DocuHub to store and process the signed document as per
          the agreed terms of use.
        </Typography>
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Consent to Conduct Transactions Electronically
      </Typography>
      <Typography paragraph>
        By using our e-signature service, you consent to conduct transactions
        electronically, including but not limited to signing contracts,
        agreements, or other documents. You also agree that all notices,
        disclosures, agreements, and other communications provided
        electronically satisfy any legal requirement that such communications be
        in writing.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Document Storage and Security
      </Typography>
      <Typography paragraph>
        <strong>Storage</strong>: Documents signed via our platform will be
        securely stored for easy access and retrieval.
      </Typography>
      <Typography paragraph>
        <strong>Security</strong>: We implement industry-standard security
        measures to protect the integrity and confidentiality of your documents,
        including encryption, access control, and regular audits.
      </Typography>
      <Typography paragraph>
        <strong>Retention</strong>: We retain signed documents as required by
        law and as specified in our Data Retention Policy. Users may also
        request to delete stored documents as permitted.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Termination of Service
      </Typography>
      <Typography paragraph>
        We reserve the right to suspend or terminate access to our e-signature
        and document management services at our discretion, including for
        violations of these Terms, misuse of the service, or in response to
        legal requirements. Upon termination, any previously signed documents
        will remain stored and accessible, as allowed under applicable data
        retention policies and relevant legal obligations.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Limitations of Liability
      </Typography>
      <Typography paragraph>To the fullest extent permitted by law:</Typography>
      <Typography component="ul" paragraph>
        <Typography component="li">
          DocuHub shall not be liable for any damages resulting from the use or
          misuse of the e-signature and document management services.
        </Typography>
        <Typography component="li">
          We make no guarantees about the validity, enforceability, or legal
          compliance of electronic signatures as used in specific transactions
          or jurisdictions.
        </Typography>
        <Typography component="li">
          Users are responsible for understanding the enforceability of
          electronic signatures for their specific use case.
        </Typography>
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Changes to Terms and Conditions
      </Typography>
      <Typography paragraph>
        DocuHub reserves the right to modify these Terms at any time. Any
        changes will be posted with a revised "Effective Date." Continued use of
        our services after such modifications shall constitute your acceptance
        of the revised Terms.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Governing Law and Jurisdiction
      </Typography>
      <Typography paragraph>
        These Terms shall be governed by and construed in accordance with the
        laws of [Jurisdiction]. Any disputes arising from these Terms or the use
        of our services shall be resolved in the courts of [Jurisdiction].
      </Typography>

      <Typography variant="h6" gutterBottom>
        11. Contact Us
      </Typography>
      <Typography paragraph>
        For questions or further information regarding these Terms, please
        contact us at:
      </Typography>

      <Typography component="ul">
        <Typography component="li">DocuHub</Typography>
        <Typography component="li">[Address]</Typography>
        <Typography component="li">[Phone Number]</Typography>
        <Typography component="li">[Email Address]</Typography>
      </Typography>

      <Typography paragraph>
        By proceeding to use our e-signature and document management feature,
        you acknowledge that you have read, understood, and agree to these Terms
        and Conditions.
      </Typography>
    </Box>
  );
}

export default SampleContract;
