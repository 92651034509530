import { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { APIEndpoint } from 'common/enum';
import { staffGroupInterface } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import CustomModal from 'components/CustomModal';
import HeaderComp from 'components/elements/HeadingComponent';
import SelectableList from 'components/ListItemsWithSearch';
import useSelectableItems from 'hooks/useSelectableList';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { getStaffGroups } from './utils';

interface staffGroupMembersInterface {
  name: string;
}
const DeleteStaffGroupMember = () => {
  const [staffGroups, setStaffGroups] = useState([] as staffGroupInterface[]);
  const [selectedStaffGroup, setSelectedStaffGroup] = useState(
    {} as staffGroupInterface
  );
  const [staffGroupMembers, setStaffGroupMembers] = useState(
    [] as staffGroupMembersInterface[]
  );
  const { selectedItems, toggleItem, resetSelection } = useSelectableItems();
  const {
    selectedItems: selectedMember,
    toggleItem: toggleSelectedMember,
    resetSelection: resetMemberSelection,
  } = useSelectableItems();
  const [loading, setLoading] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { companyId } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    getStaffGroups(companyId, setStaffGroups, setLoading);
  }, []);
  useEffect(() => {
    resetMemberSelection();
    const filteredGroup = staffGroups.filter(
      (group) => group.companyStaffGroupName == selectedItems[0]
    );
    setSelectedStaffGroup(filteredGroup[0]);
    setStaffGroupMembers(
      filteredGroup.flatMap((group) =>
        group.employeeIds.map((id) => ({ name: id }))
      )
    );
  }, [selectedItems]);
  const deleteGroupMember = async () => {
    setDeletingGroup(true);
    const payload = {
      companyId: selectedStaffGroup.companyId,
      actionName: 'DeleteAStaff',
      companyStaffGroupId: selectedStaffGroup.companyStaffGroupId,
      employeeId: selectedMember[0].split(' - ')[1],
    };
    const response = await universalPostRequest(
      APIEndpoint.deleteGroup,
      payload
    );
    setDeletingGroup(false);
    if ('error' in response) {
      popupNotification(response.error, false);
      resetMemberSelection();
      resetSelection();
    } else {
      popupNotification(response.message, true);
      getStaffGroups(companyId, setStaffGroups, setLoading);
      setStaffGroupMembers([]);
      setSelectedStaffGroup({} as staffGroupInterface);
    }
    resetSelection();
  };
  return (
    <>
      <HeaderComp>Delete staff group member</HeaderComp>
      <Grid container spacing={4}>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Staff groups
          </Typography>
          <SelectableList
            items={staffGroups}
            selectedItems={selectedItems}
            toggleItem={toggleItem}
            searchVisible={true}
            showSelectHighlight
            showCheckBoxIcon={false}
            allowSingleSelection
            loading={loading}
            idKey="companyStaffGroupName" // Nested key
            labelKey="companyStaffGroupName" // Nested label
            height={150}
          />
        </Grid>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Staff group members
          </Typography>
          <Box
            height="100%"
            sx={{
              borderRadius: 4,
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <SelectableList
              items={staffGroupMembers}
              selectedItems={selectedMember}
              toggleItem={toggleSelectedMember}
              searchVisible={true}
              showSelectHighlight
              showCheckBoxIcon={false}
              allowSingleSelection
              idKey="name" // Nested key
              labelKey="name" // Nested label
              height={150}
            />
            {/* <List>
              {staffGroupMembers.map((member, index) => (
                <>
                  <ListItem key={member}>{member}</ListItem>
                  {index !== staffGroupMembers.length - 1 && <Divider />}
                </>
              ))}
              {!staffGroupMembers.length && (
                <Typography variant="body1" textAlign="center" color={red[400]}>
                  No members
                </Typography>
              )}
            </List> */}
          </Box>
        </Grid>
        <Grid lg={12}>
          <Box display="flex" justifyContent="flex-end" mt={8}>
            <Button
              variant="contained"
              color="error"
              disabled={selectedItems.length === 0}
              onClick={() => setShowModal(true)}
            >
              Delete member
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        type="error"
        open={showModal}
        onClose={() => setShowModal(false)}
        primaryButtonAction={deleteGroupMember}
        primaryButtonLabel="Delete member"
        secondaryButtonAction={() => setShowModal(false)}
        secondaryButtonLabel="Cancel"
        primaryButtonLoading={deletingGroup}
        title={`Delete member: ${selectedMember[0]}`}
      >
        <Typography variant="body1">
          Staff group member {selectedMember[0]} will be
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: red[400] }}
            component="span"
          >
            {' '}
            Deleted{' '}
          </Typography>
        </Typography>
        <Typography variant="body1">Do you want to continue ?</Typography>
      </CustomModal>
    </>
  );
};

export default DeleteStaffGroupMember;
