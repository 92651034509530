import * as yup from 'yup';

export const validatePersonalDetails = yup.object({
  address1: yup.string().required('Address is required'),
  address2: yup.string().required('Address 2 is required'),
  address3: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('Street is required'),
  postCode: yup
    .string()
    .required('PostCode is required')
    .min(5, 'Minimum length is 5 characters'),
});
