import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import {
  Control,
  Controller,
  FieldError,
  RegisterOptions,
} from 'react-hook-form';

interface InputElementProps {
  name: string;
  control: Control<any>; // Type for React Hook Form's control prop
  rules?: RegisterOptions; // Type for rules (validation options)
  required?: boolean;
  type?: string;
}

// Use `TextFieldProps` directly when spreading into TextField
const InputElement: React.FC<InputElementProps & TextFieldProps> = ({
  name,
  control,
  rules,
  required = false,
  ...textFieldProps // Spread all other TextField props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
        fieldState: { error },
      }: {
        field: any;
        fieldState: { error?: FieldError };
      }) => (
        <TextField
          {...field}
          fullWidth
          size="small"
          margin="dense"
          required={required}
          error={!!error}
          helperText={error ? error.message : ''}
          InputLabelProps={{
            shrink: !!field.value || undefined, // Force label to shrink if value exists
          }}
          {...textFieldProps} // Apply all other TextField props
        />
      )}
    />
  );
};

export default InputElement;
