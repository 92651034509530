import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Box, Grid, Typography } from '@mui/material';

import GradientCardComponent from '../../../components/elements/GradientCard';
import useNavigateWithUUID from '../../../hooks/useNavigateWithUUID';
const Invite = () => {
  const { navigateWithUuid } = useNavigateWithUUID();
  return (
    <>
      <Box>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            background: 'linear-gradient(45deg, #42d392, #647eff)', // Gradient colors
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text', // Add this for Firefox support
            display: 'inline-block', // Ensure the gradient is only applied to the text
            fontWeight: 'semibold',
          }}
        >
          Invite user
        </Typography>
        <Grid container display="flex" gap={4} mt={5}>
          <Grid item xs={12} lg={3} md={3}>
            <GradientCardComponent>
              <Box
                display="flex"
                gap={2}
                pt={1}
                pb={1}
                onClick={() => navigateWithUuid('invite-user/invite-staff')}
              >
                <PersonAddAltIcon />
                <Typography variant="subtitle1" fontWeight="bold">
                  Invite staff user
                </Typography>
              </Box>
            </GradientCardComponent>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <GradientCardComponent>
              <Box
                display="flex"
                gap={2}
                pt={1}
                pb={1}
                onClick={() => navigateWithUuid('invite-user/invite-customer')}
              >
                <GroupAddIcon />
                <Typography variant="subtitle1" fontWeight="bold">
                  Invite customer
                </Typography>
              </Box>
            </GradientCardComponent>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <GradientCardComponent>
              <Box
                display="flex"
                gap={2}
                pt={1}
                pb={1}
                onClick={() => navigateWithUuid('invite-user/invite-corporate')}
              >
                <GroupAddIcon />
                <Typography variant="subtitle1" fontWeight="bold">
                  Invite corporate
                </Typography>
              </Box>
            </GradientCardComponent>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Invite;
